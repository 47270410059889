@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.my-gate-payment-loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f93356;
  border-top: 3px solid white;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
