.carServiceManufactureMainContainer {
  /* border: 2px solid red; */
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carServiceManufactureInputMainContainer {
  border: 0.5px solid black;
  width: 100%;
  display: flex;
  flex-direction: row;
}
/* .carServiceManufactureSearchContainer {
  width: 15%;
  height: 100%;
} */
.carServiceManufactureInputContainer {
  height: 50%;
  width: 100%;
  border: none;
  font-family: "Circular-Std-Book";
  color: #25252e;
}

.carServiceManufactureOptionsMainContainer {
  /* border: 2px solid teal; */
  width: 95%;
  height: 80%;
  margin: auto;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
}

.carServiceManufacturefirstOptionMainContainer {
  height: 40%;
  width: 30%;
  font-family: "Circular-Std-Book";
  color: #25252e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.carServiceManufacturefirstOptionMainContainer > img {
  height: 70%;
  width: 100%;
  object-fit: contain;
}

.carServiceManufacturefirstOptionText {
  font-size: 12px;
  font-weight: 500;
  font-family: "Circular-Std-Book";
  color: black;
}

@media (max-width: 767px) {
  .carServiceManufacturefirstOptionMainContainer {
    height: 25%;
    width: 30%;
  }
}
