.dateOptionReschedule {
  background-color: white;
  width: 15%;
  padding: 0.5rem;
  border-radius: 2px;
  border: 2px solid #888;
  margin-right: 0.5rem;
  margin-bottom: 5px;
  cursor: pointer;
  color: #666;
  font-family: "Circular-Std-Book";
  font-size: 14px;
}
.dateDivReschedule {
  display: flex;
  width: 95%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.7rem;
  background-color: white;
  overflow-x: scroll;
}

.dateOptionReschedule:hover {
  border: 2px solid #127e40;
  border-radius: 5px;
}
.carWashTimeModify {
  border: 2px solid gray;
  font-size: 14px;
  text-align: center;
  padding: 3px;
  margin-top: 0.5rem;
}
.carWashTimeModify:hover {
  border: 2px solid #f93356;
}
.carWashTimeWrapper {
  display: flex;
  gap: 0.5rem;
  overflow: scroll;
}

.dateDropDown{
  display: flex;
  align-items: center;
}