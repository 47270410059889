.cityMainContainerHeading {
  /* border: 2x solid red; */
  font-size: 18px;
  font-family: "Circular-Std-medium";
}
.cityInputMainContainer {
  border: 1px solid gray;
  /* border: 2px solid red; */
  width: 100%;
  /* height: 12%; */
  border-radius: 5px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.cityInputSearch {
  font-size: 18px;
}

.cityInputMainContainer > input {
  border: none;
  font-family: "Circular-Std-Book";
  outline: none;
  width: 85%;
  font-size: 18px;
}
.cityInputMainContainer > input:focus {
  border: none;
}

.cityOptionMainContainer {
  margin-top: 1.5rem;
  height: 350px;
  width: 100%;
  overflow: scroll;
}
.cityOptionContainer {
  padding: 1rem;
  font-size: 16px;
  font-family: "Circular-Std-Book";
  color: black;
  /* border-bottom: 1px solid #888; */
  border-bottom: 1.5px solid rgba(9, 8, 26, 0.08);
}

input[type="text"]:focus {
  background-color: white;
  border: none;
}
