.packageDetailsContainer {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  background: #fff;
}
.packageDetailsContainer > section {
  color: #09081a;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem; /* 128.571% */
  border-bottom: 1px solid #e6e6e8;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activeCardDetailsMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.cardLayoutDU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardLayoutDU > section {
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  width: 50%;
}
.cardLayoutDU > div {
  padding: 0.5rem 0rem 0.5rem 0rem;
  width: 50%;
  font-size: 0.875rem;
  color: #6b6b76;
  line-height: 0.875rem;
}
.packageBoxDate {
  border-radius: 0.2215rem;
  background: #009d61;
  color: white;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}
.dateBookBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.75rem;
}
.packageWrapperDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}
.customerDetails {
  display: flex;
  align-items: flex-start;
  /* border: 1px solid red; */
  gap: 0.5rem;
}
.mainPartnerDetails {
  padding: 0.75rem;
}
.customerDetails > span {
  color: #09081a;
  font-size: 0.75rem;
  line-height: 1.125rem; /* 128.571% */
  font-family: "Circular-Std-Bold";
  /* border: 2px solid green; */
  width: 90%;
}
.customerCall {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
}
.duBookDetailsBlock {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  padding: 0.25rem;
}
