

.heading4, .list, .bold, .span{
    line-height: 46px;
}
.TANDCwrapper {
    /* max-width: 1170px; */
    max-width: 60vw;
    margin: 0 auto;
    color: #444545;
    font-family: "Circular-Std-Book";
    margin-bottom: 8rem;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.termHead{
    text-align: center;
    padding-top: 20px;
}

.accDelContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    
}



@media only screen and (max-width: 1024px) {
    .TANDCwrapper {
        max-width: 100vw;
        box-shadow: none;
        padding-left: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .TANDCwrapper {
        /* max-width: 1170px; */
        max-width: 100vw;
        box-shadow: none;
        padding-left: 1rem;
    }
    .termHead{
        padding-top: 10px;
        font-size: 25px;
    }
    .heading4, .list, .bold, .span{
        line-height: 30px;
        font-size: medium;
    }
}