.carServiceFilterVideoMainContainer {
  width: 100%;
  margin: auto;
  height: auto;
  /* border: 1px solid red; */
}

.carServiceFilterVideoMainContainer > img {
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
  /* object-fit: contain; */
}

.carServiceFilterOfferContainer {
  width: 90%;
  /* height: 14%; */
  margin: auto;
  margin-top: 1rem;
  border: 1.5px solid #e6e6e8;
  border-radius: 10px;
  background: #ffffff;
}
.carServiceFilterOfferMapContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.carServiceFilterOfferLeftMainContainer {
  /* border: 1px solid red; */
  width: 10%;
  height: 100%;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carServiceFilterOfferLeftMainContainer > img {
  height: 60%;
  width: 80%;
  object-fit: contain;
}
.carSerivceFilterOfferCentreMainContaier {
  /* border: 1px solid teal; */
  height: 100%;
  /* width: 75%; */
  display: flex;
  flex-direction: column;
  row-gap: 10%;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.carSerivceFilterOfferCentreMainHeading {
  color: #09081a;
  line-height: 120%;
  font-weight: 700;
  font-family: "Circular-Std-Book";
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.carSerivceFilterOfferCentreSubMainHeading {
  color: #09081a;
  font-size: 12px;
  font-family: "Circular-Std-Book";
}

/* car Service Options start */
.carServiceFilterAllOptionMainContainer {
  /* border: 2px solid red; */
  border: 1.5px solid #e6e6e8;
  width: 90%;
  margin: auto;
  height: 50%;
  margin-top: 1.5rem;
  border-radius: 10px;
}
.carServiceFilterAllOptionTopMainContainer {
  border-bottom: 1.5px solid #e6e6e8;
  width: 95%;
  height: 40%;
  margin: auto;
  display: flex;
  align-items: center;
}
.carServiceFilterAllOptionTopContainer {
  /* border: 1px solid green; */
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carServiceFilterAllOptionTopLeftContainer {
  /* border: 1px solid teal; */
  /* justify-content: space-between; */
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  line-height: 150%;
}
.carServiceFilterAllOptionTopLeftHeading {
  font-family: "Circular-Std-Book";
  font-weight: 700;
  font-size: 16px;
  color: #09081a;
  /* border: 1px solid red; */
}
.carServiceFilterAllOptionTopLeftRating {
  font-family: "Circular-Std-Book";
  font-weight: 450;
  font-size: 14px;
  color: #aaaca7;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
}
.carServiceFilterAllOptionImg {
  /* border: 1px solid red; */
  height: 60%;
  width: 10%;
}
.carServiceFilterAllOptionImg > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.carServiceFilterAllOptionTopLeftPrice {
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 14px;
  color: #09081a;
}
.carServiceFilterAllOptionTopLeftETATime {
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 12px;
  color: #aaaca7;
}

/*  right container */
.carServiceFilterAllOptionTopRightContainer {
  /* border: 1px solid black; */
  height: 80%;
  width: 40%;
}
.carServiceFilterAllOptionTopRightContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* bottom main Container */
.carServiceFilterAllOptionBottomMainContainer {
  /* border: 1px solid red; */
  width: 90%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  margin-top: 0.5rem;
}

.carServiceFilterAllOptionBottomOptionContainer {
  /* border: 1px solid red; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  /* line-height: 150%; */
  justify-content: space-between;
}
.carServiceFilterAllOptionBottomFirstOptions {
  width: 100%;
  /* border: 1px solid teal; */
  height: 20%;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8f918d;
}

.carServiceFilterViewDetailsMainContainer {
  /* border: 2px solid pink; */
  width: 100%;
  margin: auto;
  height: 25%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  justify-content: space-between;
}
.carServiceFilterViewDetailsContaier {
  color: #009d61;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  display: flex;
  align-items: center;
}
.carServiceFilterBookNowContaier {
  font-family: "Circular-Std-Book";
  font-weight: 700;
  font-size: 12px;
  color: #009d61;
  width: 40%;
  border: 1.5px solid #e6e6e8;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
}
/* car Service Options end */

/*  react silk slide */
.slick-dots li.slick-active button:before {
  color: green; /* Active dot color */
}

.slick-dots li {
  margin: 0px;
}

/* slide show image end */

.filterCoupanCountsMainContainer {
  height: 100%;
  color: #009d61;
  /* border: 1px solid red; */
  /* margin-bottom: 0.5rem; */
}

/*  empty service arr */
.carServiceFilterEmptyMainContainer {
  /* border: 1px solid red; */
  width: 90%;
  height: 50%;
  margin: auto;
  margin-top: 1rem;
}
.carServiceFilterEmptyLottie {
  /* border: 1px solid teal; */
  height: 80%;
  width: 90%;
  margin: auto;
}
.carServiceFilterEmptyMainHeading {
  text-align: center;
  color: #9b9b9b;
  margin-top: -2rem;
  font-size: 14px;
}

@media (max-width: 767px) {
  .carServiceFilterMainContainer {
    width: 100%;
  }
}
