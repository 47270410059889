:root {
  --font-headingBuy: 24px;
  --font-contentBuy: clamp(1rem, 1.181vw, 1.063rem);
}
.BuyAndSell_MainDiv {
  max-width: 120em;
  height: 500px;
  max-width: 120em;
  background-color: #f3f3f4;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}
.MobileMapImage {
  max-width: 60%;
  height: 100%;
}
.BuyAndSell_TextDiv {
  width: 40%;
  height: auto;
  margin-right: 2rem;
}
.Buy_Sell_Heading {
  width: 346px;
  height: 40px;
  margin-left: -2.5rem;
  font-family: "Circular-Std-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-headingBuy);
  line-height: 40px;
  color: #09081a;
}
.Buy_Sell_Content {
  width: 452px;
  height: auto;
  font-family: "Circular-Std-Book";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-contentBuy);
  line-height: 150%;
  color: rgba(9, 8, 26, 0.3);
  margin-top: 1em;
}
.App_Store_Image {
  max-width: 80%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  gap: 5%;
  cursor: pointer;
  margin-top: 20px;
}
.GooglePlay,
.ApplePlay {
  max-width: 100%;
}
@media (min-width: 1280px) and (max-width: 1535px) {
  .BuyAndSell_TextDiv {
    width: 400px;
    margin-left: -96px;
  }
  .App_Store_Image {
    max-width: 80%;
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    cursor: pointer;
    margin-top: 20px;
  }
  .GooglePlay,
  .ApplePlay {
    max-width: 100%;
  }
}
@media (min-width: 1314px) and (max-width: 1430px) {
  .BuyAndSell_TextDiv {
    width: 400px;
    margin-left: -9px !important;
  }
  .BuyAndSell_MainDiv {
    max-width: 120em;
    height: 500px;
    max-width: 120em;
    background-color: #f3f3f4;
    margin-top: -50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 00px;
  }
  .MobileMapImage {
    max-width: 60%;
    height: 100%;
    margin-left: -60px;
  }
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .BuyAndSell_TextDiv {
    margin-left: -96px;
  }

  .MobileMapImage {
    max-width: 50%;
    min-height: 100%;
  }
  .BuyAndSell_MainDiv {
    width: 100%;
    height: auto;
  }
  .App_Store_Image {
    max-width: 80%;
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    cursor: pointer;
    margin-top: 20px;
  }
  .GooglePlay,
  .ApplePlay {
    max-width: 100%;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .BuyAndSell_MainDiv {
    height: 300px !important;
  }
  .BuyAndSell_TextDiv {
    width: 50%;
    margin-left: -96px;
  }
  .MobileMapImage {
    max-width: 50%;
    min-height: 100%;
  }
  .BuyAndSell_MainDiv {
    width: 100%;
    height: auto;
  }
  .Buy_Sell_Content {
    width: 80%;
    height: auto;
    font-family: "Circular-Std-Book";
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-contentBuy);
    line-height: 150%;
    color: rgba(9, 8, 26, 0.3);
    margin-top: 1em;
  }
  .App_Store_Image {
    max-width: 80%;
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    cursor: pointer;
    margin-top: 20px;
  }
  .GooglePlay,
  .ApplePlay {
    max-width: 100%;
  }
}
@media (min-width: 1023px) and (max-width: 1072px) {
  .BuyAndSell_TextDiv {
    width: 50%;
    margin-left: -30px;
  }
}
@media (max-width: 767px) {
  .BuyAndSell_MainDiv {
    width: 100%;
    background-color: white;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .MobileMapImage {
    max-width: 100%;
    object-fit: contain;
  }
  .BuyAndSell_TextDiv {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: -50px;
  }
  .Buy_Sell_Heading {
    width: 100%;
    margin-left: 0rem;
    text-align: center;
    font-family: "Circular-Std-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-headingBuy);
    color: #09081a;
  }
  .Buy_Sell_Content {
    width: 100%;
    height: auto;
    font-family: "Circular-Std-Book";
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-contentBuy);
    line-height: 150%;
    color: rgba(9, 8, 26, 0.3);

    text-align: center;
    margin-top: 0px;
  }
  .App_Store_Image {
    max-width: 100%;
    min-height: 0px;
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1536px) {
  .BuyAndSell_MainDiv {
    margin: auto;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .BuyAndSell_MainDiv {
    width: 100%;
    height: fit-content !important;
    background-color: white;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
