:root {
  --font-size-WhyDriveUHead: 24px;
  --font-size-WhyDriveUContent: clamp(1rem, 1.181vw, 1.063rem);
}
.WhyDriveU_Main_div {
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 96%;
  margin: auto;
  /* margin-top: 5%; */
  height: auto;
}
.WhyDriveU_Mobile_div {
  display: none;
}
.WhyDriveU_Main_div_first,
.WhyDriveU_Main_div_sec,
.WhyDriveU_Main_div_thi,
.WhyDriveU_Main_div_four {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  padding-top: 2%;
}

.WhyDriveU_Main_div_first_img,
.WhyDriveU_Main_div_sec_img,
.WhyDriveU_Main_div_thi_img,
.WhyDriveU_Main_div_four_img {
  width: 58%;
  margin-bottom: 5%;
}

.WhyDriveU_Main_div_first_img > img,
.WhyDriveU_Main_div_sec_img > img,
.WhyDriveU_Main_div_thi_img > img,
.WhyDriveU_Main_div_four_img > img {
  min-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.WhyDriveU_heading_text,
.WhyDriveU_subHeading_text {
  padding-bottom: 8%;
}
.WhyDriveU_heading_text {
  font-size: var(--font-size-WhyDriveUHead);
  font-weight: 700;
  font-family: "Circular-Std-Bold";
  width: 100%;
  text-align: center;
}

.WhyDriveU_subHeading_text {
  color: rgba(9, 8, 26, 0.3);
  font-family: 450;
  font-family: "Circular-Std-Book";
  width: 100%;
  font-size: var(--font-size-WhyDriveUContent);
}
@media (max-width: 768px) {
  .WhyDriveU_Main_div {
    display: none;
  }
  .WhyDriveU_Mobile_div {
    display: block;
    width: 86%;
    margin: auto;
    margin-top: 15%;
    display: flex;
    gap: 5px;
  }
  .WhyDriveU_Main_div_first,
  .WhyDriveU_Main_div_sec,
  .WhyDriveU_Main_div_thi,
  .WhyDriveU_Main_div_four {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .WhyDriveU_Main_div_first_img,
  .WhyDriveU_Main_div_sec_img,
  .WhyDriveU_Main_div_thi_img,
  .WhyDriveU_Main_div_four_img {
    width: 30%;
    margin-bottom: 10%;
    margin: auto;
  }

  .WhyDriveU_Main_div_first_img > img,
  .WhyDriveU_Main_div_sec_img > img,
  .WhyDriveU_Main_div_thi_img > img,
  .WhyDriveU_Main_div_four_img > img {
    min-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .WhyDriveU_heading_text,
  .WhyDriveU_subHeading_text {
    padding: 3%;
  }
  .WhyDriveU_heading_text {
    font-size: var(--font-size-WhyDriveUHead);
    font-weight: 700;
    font-family: "Circular-Std-Bold";
    width: 100%;
    text-align: center;
  }

  .WhyDriveU_subHeading_text {
    color: rgba(9, 8, 26, 0.3);
    font-family: 450;
    font-family: "Circular-Std-Book";
    width: 100%;
    text-align: center;
    height: 100%;
    font-size: var(--font-size-WhyDriveUContent);
  }
}
