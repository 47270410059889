.halfRating {
  width: 14px;
  height: 14px;
  margin-top: 0.2rem;
}
@media (max-width: 767px) {
  .halfRating {
    width: 12px;
    height: 12px;
    margin-top: 0.15rem;
  }
}
