.myGateFormAddSubMainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: c; */
}
.myGateFormAddSubMainContainerLocation {
  width: 10%;
  height: 80%;
  padding: 0.5rem;
}
.myGateFormAddSubMainContaineInput {
  width: 100%;
  height: 80%;
  color: #09081a;
}

.myGateFormAddSubMainContaineInput > input {
  width: 100%;
  height: 80%;
  padding: 0.5rem;
  border: none;
  font-size: 12px;
  /* color: rgba(9, 8, 26, 0.4); */
  color: #09081a;
  background: rgba(9, 8, 26, 0);
  outline: none;
}

input[type="search"]:focus {
  outline-offset: 0px !important;
  --tw-ring-color: transparent !important;
  border-color: transparent;
}

/* #Search::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
  } */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 12px;
  width: 12px;
  /* background-image: url("../../PNGImage/Close_Button.png"); */
  display: block;
  background-repeat: no-repeat;
  background-size: 12px;
}

.carServiceAddressList {
  height: auto;
  overflow: scroll;
  font-size: 10px;
  color: rgb(107 114 128);
  border-bottom: 0.4px solid rgb(243 244 246);
  cursor: pointer;
  padding-bottom: 0.1rem;
  /* border-radius: 5px; */
}
.carServiceAddressList:hover {
  background-color: 0.3px solid rgb(243 244 246);
  color: rgb(75 85 99);
}
