:root {
  --font-Circular-Medium: "Circular-Std-Medium";
  --font-Circular-Book: "Circular-Std-Book";
}
.business-wrapper {
  max-width: 320px;
  height: 220px;
  width: 80%;
  margin: auto;
  margin-top: 1rem;
  background: #ffffff;
  position: absolute;
  top: 100%;
  background: #ffffff;
  border: 0.5px solid rgba(9, 8, 26, 0.2);
  box-shadow: 4px 4px 44px rgba(0, 5, 46, 0.08);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.business-content-box {
  width: 80%;
  height: 78.5%;
  display: grid;
  grid-template-columns: repeat(1, 90%);
  /* grid-gap: 0% 0%; */
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.main-business {
  width: auto;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
  cursor: pointer;
}
.business-svg {
  width: 52px;
  height: 52px;
}
.business-dd-content {
  width: 187px;
  height: 64px;
}
.article-business {
  font-size: 14px;
  font-family: var(--font-Circular-Book);
  opacity: 0.4;
  font-weight: 450;
  line-height: 18px;
  color: #09081a;
}
.section-business {
  font-family: var(--font-Circular-Medium);
  font-weight: 500;
  color: #09081a;
  line-height: 20px;
  font-size: 16px;
}

.main-business:hover .svg-option {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media (min-width: 1023px) and (max-width: 1279px) {
  .business-wrapper {
    max-width: 298px;
    height: 200px;
  }
  .business-content-box {
    width: 44%;
    height: 78.5%;
    display: grid;
    grid-template-columns: repeat(1, 90%);
    grid-gap: 16.9% 8.55%;
  }
  .main-business {
    width: auto;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 19px;
  }
  .business-svg {
    width: 52px;
    height: 52px;
  }
  .business-dd-content {
    width: 187px;
    height: 64px;
  }
  .article-business {
    font-size: 11px;
    font-family: var(--font-Circular-Book);
    opacity: 0.4;
    font-weight: 450;
    line-height: 18px;
    color: #09081a;
  }
  .section-business {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 12px;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .business-wrapper {
    max-width: 33%;
    height: 170px;
  }
  .business-content-box {
    width: 94%;
    height: 85%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 90%);
  }
  .main-business {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .main-business:hover .svg-option {
    width: 56px;
    height: 56px;
  }

  .business-dd-content {
    width: 187px;
    display: flex;
    flex-direction: column;
  }

  .section-business {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 11px;
  }
  .article-business {
    font-size: 11px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .business-wrapper {
    width: 55%;
    height: 280px;
  }
  .business-content-box {
    width: 94%;
    height: 85%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 29%);
    grid-gap: 8% 6.55%;
  }
  .main-business {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .business-svg {
    width: 48px;
    height: 48px;
  }
  .business-dd-content {
    width: 187px;
    display: flex;
    align-items: center;
  }
  .article-business {
    display: none;
  }
  .section-business {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .business-wrapper {
    width: 100%;
    height: 35vh;
    border-radius: 10px;
    margin-top: 0.5rem;
    margin-left: 10px;
  }
  .business-content-box {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 90%);
    grid-gap: 3% 3%;
    overflow: scroll;
  }
  .main-business {
    width: 100%;
    min-height: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .business-svg {
    max-width: 100%;
    width: 40%;
    height: 100%;
  }
  .svg-option {
    width: 100%;
    height: 100%;
  }
  .business-dd-content {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }
  .article-business {
    display: none;
  }
  .section-business {
    font-family: var(--font-Circular-Book);
    font-weight: 700;
    color: #09081a;
    line-height: 10px;
    font-size: 10px;
    text-align: left;
  }
}
