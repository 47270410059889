.privacy-policy-wrapper {
  width: 90%;
  margin: auto;
  margin-bottom: 60px;
  padding: 40px 0;
  color: #444545;
}

.privacy-policy-wrapper span.bold {
  font-weight: 400;
}
.privacy-policy-wrapper .head {
  color: #2cae72;
  font-size: 25px;
  font-weight: 700;
  margin: 40px 0 20px;
  text-transform: uppercase;
  font-family: "Circular-Std-Bold";
}
.privacy-policy-wrapper p {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Circular-Std-Book";
}
.privacy-policy-wrapper .sub-head {
  text-transform: uppercase;
  margin: 40px 0 10px;
  font-weight: 500;
  font-family: "Circular-Std-Book";
  font-weight: 700;
}
.definitionList > ol,ul{
  list-style-type: lower-alpha; 
  padding-left: 40px;
}
.definitionList > ol,ul li {
  font-size: 14px;
  line-height: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .refund-policy-wrapper {
    /* border: 2px solid red; */
    width: 85%;
  }
}
