.mapbox-map {
  width: 100%;
  height: 45vh;
  background-color: #f0f0f0;
  /* color: ; */
}
.locationDetails {
  width: 100%;
  background-color: #f93356;
  height: 50vh;
}
.locationWrapper {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 50vh;
}
.bookDriverMsg {
  width: 100%;
  height: 5vh;
  background-color: #f93356;
  /* #127E40 */
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 8px;
  padding: 4px;
}
.bookDriverDetails {
  background-color: white;
  margin: auto;
  padding: 0px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 96%;
}
.bookingFirstBlock {
  /* height: 15vh; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 12px;
  width: 98%;
}
.washQuantityWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  color: #09081a;
}
.washQuantity {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid red; */
}
.quantityBlock {
  /* border: 3px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 2rem;
}
.locationFirstBlock {
  width: 100%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
}
.msg-popup {
  width: 100%;
  height: 100%;
}
.msgPopUpDiv {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tickMark {
  background-color: #ffffff;
  color: #28950c;
}
.bookScheduledMsg {
  font-weight: 700;
  font-size: 18px;
  padding: 0px 0px 0px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #09081a;
}
.date-scheduled {
  font-size: 16px;
  padding-left: 5px;
  color: #09081a;
  opacity: 0.8;
  font-weight: 400;
}
.bookTypeApp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.modifyBooking {
  font-size: 10px;
  color: #f93356;
  text-decoration: underline;
}
.oneWayTrip {
  font-size: 16px;
  opacity: 0.4;
  color: #09081a;
  width: 100%;
}
.pickUpDropBlock {
  width: 95%;
  margin: auto;
  height: 100%;
  display: flex;
  padding: 5px;
  gap: 8px;
}
.location-dotted-line {
  border: 1px dotted black;
  height: 6vh;
}
.locationDot {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pickup-Place {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.pickup-place-first {
  font-size: 13px;
  color: #000000;
  opacity: 0.6;
}
.drop-location {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}
.drop-place {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  opacity: 0.6;
}
.paymentTextMyGate {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  opacity: 0.6;
}
.locationWrapperBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.pickupBlock {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.InsuranceContainer {
  width: 100%;
  height: 25vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* .HelpModifyContainer {
  width: 100%;
  
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0.5rem;
  font-weight: 700;
  font-size: 14px;
  
} */
/* height: 25vh; */
.PaymentContainer {
  width: 100%;
  height: 25vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 0.5rem;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}
.InsuranceTitle {
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-weight: 700;
  font-size: 18px;
}
.InsuranceText {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.ModifyButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.GetSupportBtn {
  background-color: white;
  color: #09081a;
  opacity: 0.8;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 5px;
  width: 45%;
  font-size: 12px;
}
.CallDriver {
  /* color: #f93356; */
  border: none;
  font-size: bold;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  text-align: center;
  border-radius: 5px;
  width: 30%;
  font-size: 12px;
}
.ModifyBtn {
  background-color: #f93356;
  color: white;
  border: none;
  font-size: bold;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px;
  padding-left: 0px;
  border-radius: 5px;
  width: 45%;
  font-size: 12px;
}
.DriverDetailsContainer {
  display: flex;
  align-items: center;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 20px;
  gap: 0.5rem;
  padding: 0.5rem;
  flex-direction: column;
}
.show_details {
  width: 100%;
  color: white;
  text-align: center;
  padding: 5px;
}
.call_details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  color: white;
  /* text-align: center; */

  padding: 10px;
  border: none;
  border-radius: 50%;
}

.DriverImageWrapper {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DriverDetailsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.TripBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.supportContainer {
  width: 100%;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.supportContainerWash {
  width: 100%;
  height: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.supportWrapper {
  padding: 1rem 0.5rem 1rem 0.5rem;
  font-size: 14px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 100%;
}
.mapContainer {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.supportWrapperArrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatNow {
  border: none;
  color: #f93356;
  border: 1px solid #000000;
  padding: 0.5rem;
  background-color: white;
  margin-top: 0.5rem;
  font-weight: 700;
  cursor: pointer;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.car-service-place {
  font-weight: 400;
  font-size: 13px;
  color: white;
  opacity: 1;
  background-color: #f93356;
  border-radius: 4px;
  padding: 4px;
}
.fareBreakUpCarService {
  /* border: 1px solid red; */
  font-family: "Circular-Std-Book";
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.modifyBookingPopUp {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modifyBookingPopUp div {
  min-height: 35px;
  width: 45%;
  /* background-color: black; */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  border: 2px solid gray;
}
.reschedulePopUp {
  min-height: 35px;
  width: 45%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  border: 2px solid black !important;
}

/* http://waitanimate.wstone.io/#!/ */

.livePopUpMsg {
  animation: myAnim 4s ease-in-out 2s 1 normal forwards;
  font-size: 12px;
  color: #ffffff;
}

@keyframes myAnim {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateX(0px);
  }

  20%,
  40%,
  60% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(-8px);
  }
}
