.roundWayImgDiv {
  width: 100%;
  height: 269px;
  background-image: url("https://www.driveu.in/static/web/v3/images/roundTrip.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.RoundWayCarType {
  width: 96%;
  margin-top: 0.3rem;
}

.packageDiv {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: white;
  overflow-y: scroll;
  margin-top: 0.5rem;
  /* height: 170px; */
  height: auto;
  overflow-y: scroll;
}

.packageDate {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.2px solid #888;
}
.packageDateInput {
  height: 43px;
  border: none;
  color: #888;
  cursor: pointer;
  margin-left: 0.5rem;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 16px;
}
.packageDateInput:focus {
  outline: none !important;
}
.packageDateInput ::placeholder {
  margin-left: 2px !important;
}
.packageinputImage {
  width: 7.8%;
  margin-top: 10px;
}
.packageinputImage > img {
  height: 5px;
  width: 10px;
}
.packageOption {
  background-color: white;
  width: 100%;
  padding: 0.2rem;
  border-radius: 2px;
  margin-right: 0.5rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  color: #666;
}

.packageOption:hover {
  border: 2px solid #127e40;
  border-radius: 5px;
}
.RoundWayPkgBlock {
  font-family: "Circular-Std-Book";
  margin-bottom: 0.8rem;
}
.fontRoundWay {
  font-family: "Circular-Std-Medium";
  width: 96%;
}
