* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  font-family: "Circular-Std-Book";
}
*::-webkit-scrollbar {
  display: none;
}
.App {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
