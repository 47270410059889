.fareTable,
.fareTableRow,
.fareTableCol {
  border: none;
}
.fareTable {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.fareTableCol {
  color: #09081a;
  font-size: 14px;
  margin: auto;
  text-align: center;
  width: 40%;
}
.fareTableRow {
  width: 40%;
  text-align: right;
}
.fareExtraText {
  margin-top: 1rem;
  display: flex;
  overflow: scroll;
  justify-content: flex-start;
  gap: 1rem;
}
.fareText {
  background-color: #ffffff;
  border: 1px solid #b5b58a;
  color: #61616c;
  padding: 5px;
  min-width: 150px;
  width: 150px;
}
.cancelPolicyText {
  margin-top: 1rem;
  text-align: center;
  color: #127e49;
}
.farePopUpText {
  /* border: 1px solid red; */
  background-color: #ffffff;
  padding: 0.5rem;
  width: 90%;
  margin: auto;
}
@media (max-width: 768px) {
  .fareTable {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
