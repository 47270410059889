.roundWay_Input_Tag_Div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e6e6e8;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.inputWrapper label {
  font-weight: 600;
}

.dailyCloseBtn {
  padding: 5px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roundsuggestion {
  cursor: pointer;
  padding: 10px;
}
.secondInputLocation {
  width: 90%;
  margin: auto;
}

.first_Input_Tag {
  font-size: 14px;
  border: none;
  background: #fff;
}

.debounceBox {
  background-color: white;
  border: 0.476142px solid rgba(9, 8, 26, 0.2);
  box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
  border-radius: 5px;
  color: #666;
  position: absolute;
  z-index: 1000;
  margin-top: 0.5rem;
}

.errorDivDU {
  color: red;
  width: 90%;
  margin: auto;
  font-size: 14px;
}
