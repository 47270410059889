.ResponsiveLottie {
  width: 100%;
  height: 56.25vw;
  overflow: hidden;
}
@media (max-width: 768px) {
  .ResponsiveLottie {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
