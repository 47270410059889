:root {
  --font-size-cs: clamp(1.375em, 3.333vw, 3em);
  --font-size-cscontent: clamp(1.125em, 1.667vw, 1.5em);
}
.Static_main_Div {
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: row;
  min-height: 277px;
  max-height: 277px;
  height: 277px;
  justify-content: center;
  align-items: center;
  gap: 4%;
}

.Static_first_Div,
.Static_sec_Div,
.Static_thi_Div {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10%;
  box-shadow: 2.96623px 2.96623px 32.6285px rgba(0, 5, 46, 0.08);
  border-radius: 5%;
  text-align: left;
}
.Static_first_Div_text,
.Static_sec_Div_text,
.Static_thi_Div_text {
  height: 50%;
  padding: 5%;
}

.Static_first_Div_img,
.Static_sec_Div_img,
.Static_thi_Div_img {
  height: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 3%;
  margin-right: 5%;
}

.Static_first_Div_text > .Static_text_first,
.Static_sec_Div_text > .Static_text_sec,
.Static_thi_Div_text > .Static_text_thi {
  font-size: var(--font-size-cs);
  font-weight: 700;
  font-family: "Circular-Std-Bold";
  margin-bottom: 3%;
}
.Static_first_Div_img_Div,
.Static_sec_Div_img_Div,
.Static_thi_Div_img_Div {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cs-content {
  font-size: var(--font-size-cscontent);
  color: rgba(9, 8, 26, 0.3);
  font-family: "Circular-Std-Book";
}
.cs-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 72px;
  max-height: 72px;
  float: right;
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .Static_main_Div {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    min-height: 277px;
    max-height: 277px;
    height: 277px;
    justify-content: center;
    align-items: center;
    gap: 4%;
  }
  .Static_first_Div_img,
  .Static_sec_Div_img,
  .Static_thi_Div_img {
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 3%;
    margin-right: 5%;
  }
  .Static_first_Div_img_Div,
  .Static_sec_Div_img_Div,
  .Static_thi_Div_img_Div {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .Static_main_Div {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    min-height: 277px;
    max-height: 277px;
    height: 277px;
    justify-content: center;
    align-items: center;
    gap: 4%;
  }
  .Static_first_Div_img,
  .Static_sec_Div_img,
  .Static_thi_Div_img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: auto;
    width: 100%;
  }
  .Static_first_Div_img_Div,
  .Static_sec_Div_img_Div,
  .Static_thi_Div_img_Div {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Static_first_Div,
  .Static_sec_Div,
  .Static_thi_Div {
    width: 32%;
    height: 80%;
    display: flex;
    flex-direction: column;
    gap: 10%;
    box-shadow: 2.96623px 2.96623px 32.6285px rgba(0, 5, 46, 0.08);
    border-radius: 5%;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .Static_main_Div {
    width: 85%;
    min-height: 620px;
    max-height: 620px;
    height: 620px;
    margin: auto;
    margin-top: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4%;
  }
  .Static_first_Div_img,
  .Static_sec_Div_img,
  .Static_thi_Div_img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: auto;
    width: 100%;
  }
  .Static_first_Div_img_Div,
  .Static_sec_Div_img_Div,
  .Static_thi_Div_img_Div {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Static_first_Div,
  .Static_sec_Div,
  .Static_thi_Div {
    width: 80%;
    height: 80%;
    max-width: 334px;
    display: flex;
    flex-direction: column;
    gap: 10%;
    box-shadow: 2.96623px 2.96623px 32.6285px rgba(0, 5, 46, 0.08);
    border-radius: 5%;
    text-align: left;
  }
}
