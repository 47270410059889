.nav-booking-flow-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 60px;
  width: 90%;
  background-color: white;
  z-index: 1;
}
.booking-block-search {
  width: 31.33%;
}
.driveu-logo-booking {
  width: 31.33%;
  text-align: center;
}
#booking-login-block {
  width: 31.33%;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hover-logout,
.hover-my-drives {
  font-size: 17px;
  font-family: "Circular-Std-Book";
  font-weight: 450;
  padding: 5px;
  width: 100%;
  padding: 10px;
  color: #000000;
  text-decoration: none;
  outline: none;
}
.hover-logout:hover,
.hover-my-drives:hover {
  background-color: #f6f6f6;
}
.ham-close-menu-booking {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.HamburgerIcon {
  font-size: 30px;
  cursor: pointer;
}
