/* Module Css use For avoiding Global Scope and Local Scoping */
.dailyMainContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  height: 95vh;
  overflow: scroll;
}
.reviewMainContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: auto;
  overflow: scroll;
}
.dailyBookingWrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.paymentDailyBtn {
  width: 100%;
}
.dailyPaymentWrapper {
  width: 90%;
  margin: auto;
  height: 140vh;
}
.CarType {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 20px;
  position: relative;
}
.packageWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  margin-top: 0.5rem;
  overflow: scroll;
}
.packageWrapperDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}
.packageBox {
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 0.35713rem;
  border: 1px solid #e6e6e8;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.packageBoxDate {
  border: 1px solid #e6e6e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 4.4rem;
  width: 4.3rem;
  height: 56px;
  border-radius: 0.35713rem;
}
.dailyInsurance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.InstructionsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-bottom: -0.75rem;
  position: relative;
}
.textAreaBox {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.addInsBtn {
  color: #127e40;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.dateBookBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.miniCheck {
  position: absolute;
  top: -1%;
  left: 75%;
}
.timeDiv {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fcfdf7;
  border-radius: 8px;
  margin-bottom: 5px;
}
.languageDiv {
  height: 100%;
  padding: 0px 10px 0px 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  font-weight: 500;
}
.scheduleBtn {
  width: 100%;
  height: 100px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.payBtn {
  width: 100%;
  /* height: 120px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;
  right: 0;

  margin-top: 9px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  padding: 20px 16px 16px 20px;
  background: white;
  border-radius: 5px 5px 0px 0px;
}
.payBtn > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  gap: 0.5rem;
  /* border: 1px solid red; */
  padding: 5px;
}
.payBtn > div > p {
  width: 100%;
}
.dateBtnSheet {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 8px;
}
.bookingShowDate {
  margin-top: 0.75rem;
  text-align: center;
}
.dateLanguageDiv {
  color: #000;
  font-weight: 600;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  height: 40px;
}
.dateBottomSheet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.billDetailsContainer {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}
.billDetailsContainer > strong {
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
  border-bottom: 1px solid #e6e6e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billDetailsWrapper {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.fareBreakUpDaily div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.fareDetailsDU {
  color: #61616c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 450;
  line-height: 1.125rem; /* 128.571% */
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999; /* Ensure the overlay is above other content */
  pointer-events: auto; /* Allow pointer events on the overlay */
}
.fareText {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.5rem;
}
.fareText div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
}

.fareText > ul > li {
  color: #61616c;
  font-size: 12px;
  font-weight: 450;
  line-height: 0.875rem; /* 140% */
}

.roundTripShow,
.oneWayShow {
  background-color: #fff066;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 0px 0px 12px 12px;
  gap: 12px;
}
