* {
  font-family: "Circular-Std-Book";
}

.carServiceViewDetailMainContainer {
  width: 100%;
  height: 83%;
  margin-bottom: 2%;
}

/*  middle container style */

.carServiceViewDetailHeadingMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 180%;
}
.viewDetailsHeading {
  font-family: "Circular-Std-Medium";
  font-weight: 500;
  color: black;
  font-size: 21px;
  margin-bottom: 0.3rem;
}
.viewDetailTime {
  color: gray;
  font-size: 14px;
}

.viewDetailPrice {
  color: green;
  font-size: 14px;
}

.carServiceViewDetailBottomMainContainer {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  overflow: scroll;
}

.carServiceViewDetailsCoinsMainContainer {
  width: 100%;
  height: 16%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
}
.carServiceViewDetailsCoinsHeading,
.viewDetailsIncludedHeadingContainer,
.viewDetailNeededHeadingMainContainer,
.viewDetailWorkHeadingMainContaier,
.viewDetailAboutHeadingMainContainer,
.viewDetailFAQHeadingMainContainer {
  color: gray;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
}

.emojiImageMainContainer {
  width: 15px;
  height: 15px;
}

.carServiceViewDetailsCoinsSubHeading,
.viewDetailServiceOptionContainer,
.viewDetailWorkURLMainContaier,
.viewDetailNeededOptionMainContainer,
.viewDetailAboutOptionMainContainer,
.viewDetailFAQOptionMainContainer {
  color: black;
  width: 95%;
  margin-left: 5%;
  font-size: 12px;
  line-height: 120%;
}
.viewDetailServiceOptionContainer {
  width: 100%;
}
.viewDetailsIncludedMainContainer {
  width: 100%;
  height: auto;
  display: flex;
  /* border: 1px solid red; */
  flex-direction: column;
  gap: 0.5rem;
}
.viewDetailServiceOptionContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}
.viewDetailsOption,
.viewDetailWorkURLContaier,
.viewDetailNeededOptionContainer,
.viewDetailAboutOptionContainer,
.viewDetailFAQOptionContainer {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  /* border: 1px solid red; */
  line-height: 120%;
}

/* .viewDetailsOption div {
  border: 1px solid teal;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
} */

.carServiceViewDetailImageMainContainer {
  height: 20%;
  width: 95%;
  margin-left: 5%;
  /* margin-top: 1rem; */
}
.carServiceViewDetailImageMainContainer > img {
  height: 100%;
  width: 20%;
  /* border: 1px solid red; */
}

.viewDetailWorkMainContaier {
  /* border: 1px solid red; */
  width: 100%;
  height: 15%;
  /* margin-top: 1rem; */
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.viewDetailNeededMainContainer,
.viewDetailAboutMainContainer,
.viewDetailFAQMainContainer {
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* margin-top: 1rem; */
}

.viewDetailNeededSubHeadingMainContainer {
  margin-left: 1rem;
}
.viewDetailNeededOptionContainer,
.viewDetailAboutOptionContainer,
.viewDetailFAQOptionContainer {
  line-height: 150%;
}
/* end middle container style */

.carServiceViewDetailBookContainer {
  width: 100%;
  height: 14%;
  border: 1px solid transparent;
  /* border: 2px solid red; */
}
.carServiceViewDetailButtonContainer {
  width: 95%;
  margin: auto;
  background-color: black;
  border-radius: 10px;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  height: 60%;
  color: white;
  /* margin-top: 0.7rem; */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
}
.carServiceViewDetailsCoinsHeading,
.viewDetailsIncludedHeadingContainer,
.viewDetailWorkHeadingMainContaier,
.viewDetailNeededHeadingMainContainer,
.viewDetailAboutHeadingMainContainer,
.viewDetailFAQHeadingMainContainer,
.carServiceViewDetailsCoinsSubHeading,
.viewDetailsOption,
.viewDetailNeededSubHeadingMainContainer,
.viewDetailNeededOptionMainContainer,
.viewDetailAboutOptionMainContainer,
.viewDetailFAQOptionMainContainer {
  color: #61616c;
}
/* /#09081A */
.carServiceFAQQuestionMainContaier {
  color: #09081a;
  opacity: 0.6;
}

.carServiceTickMainContainer {
  /* border: 1px solid red; */
  width: 10%;
  height: 100%;
}
.carServiceTickMainContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* .carServiceGreenTick {
  height: 15px;
  width: 15px;
} */

.FAQSCarServiceMainContainer {
  margin-bottom: 0.5rem;
}

.viewDetailsIncludedHeadingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid green; */
}
.carServiceGreenTick {
  height: 100%;
  width: 5%;
}
.carServiceGreenTick > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.carServiceGreenTickText {
  height: 70%;
  align-items: center;
  /* border: 1px solid red; */
}

.viewDetailsOption {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.carServiceTickMainContainerText {
  font-size: 12px;
  width: 90%;
}
