/* Module Css use For avoiding Global Scope and Local Scoping */
.landingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: black;
  height: 68px;
  z-index: 1;
}
.imageContainer {
  width: 100%;
}
.imageContainer > img {
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
  /* object-fit: contain; */
}
.contentPartnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 68%;
  /* height: 70%; */
  /* height: 69vh; */
  margin-top: 1rem;
}
.reservation-card1 {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 0rem; */
}
.scrollBooking {
  width: 90%;
  margin: auto;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  height: auto;
}
.reservation-card {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 25px;
}
.daily-driver-container1 {
  box-sizing: border-box;
  flex: 0 0 auto;
}
.image-container1 {
  box-sizing: border-box;
  display: block;
  width: 30px;
  max-width: initial;
  height: 30px;
  object-fit: contain;
}

.daily-driver-reservation-heading {
  box-sizing: border-box;
  flex: 0 1 auto;
  color: black;
  font-size: 16px;
  padding: 8px;
  width: 100%;
}

.reservation-container {
  margin-top: 9px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}
.reservation-button-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  background: white;
  border-radius: 5px 5px 0px 0px;
}
.reserve-button {
  width: 100%;
}

.scroll-active-package {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-x: scroll;
}

.blue-box {
  /* font-size: 14px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  color: #09081a;
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
}
.cardShower {
  /* border: 1px solid red; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 196px;
  min-width: 196px; /* Set a minimum width to maintain the specified width */
  height: 140px;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.625rem;
}

.contentWrapper {
  height: 90vh;
}
.shimmer {
  /* position: relative;
  overflow: hidden; */
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 196px;
  min-width: 196px; /* Set a minimum width to maintain the specified width */
  height: 140px;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.625rem;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
