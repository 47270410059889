:root {
  --nav-color: #09081a;
  --font-heading-lottie: clamp(1.75rem, 3.611vw, 3.25rem);
  --font-content-lottie: clamp(1rem, 1.528vw, 1.375rem);
  --font-content-lottie-download: clamp(1rem, 1.528vw, 1.375rem);
}
.wrapper-content-lottie {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  max-width: 90em;
  height: 712px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.wrapper-content {
  width: 50%;
  height: 100%;
}
.wrapper-lottie {
  width: 50%;
  height: 100%;
  margin-left: 95px;
}
.content_hire {
  width: 570px;
  height: 407px;
  margin-left: 96px;
  margin-top: 50px !important;
}
.content_lottie {
  width: 559px;
  height: 537px;
  margin-top: 127px;
}
.content_section_heading {
  font-size: var(--font-heading-lottie);
  font-family: "Circular-Std-Bold";
  color: var(--nav-color);
  margin-top: 20px;
  line-height: 58px;
  letter-spacing: -2px;
}
.content_subsection {
  font-size: 16.79px;
  font-family: "Circular-Std-Medium";
  color: #127e40;
}
.content_subsection_heading {
  font-family: "Circular-Std-Book";
  color: rgba(9, 8, 26, 0.3);
  margin-top: 46px;
  font-size: var(--font-content-lottie);
}
.btn_download_bookDriver {
  margin-top: 47px;
}
.book_driver {
  width: 282px;
  height: 67px;
  background-color: var(--nav-color);
  color: #ffffff;
  font-family: "Circular-Std-Book";
  font-size: var(--font-content-lottie);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}
.download_app {
  width: 208px;
  height: 67px;
  color: var(--nav-color);
  font-family: "Circular-Std-Book";
  font-size: var(--font-content-lottie);
  background-color: #ffffff;
  margin-left: 20px;
  background-repeat: no-repeat;
  cursor: pointer;
  border: 2px solid var(--nav-color);
}
.download_app:hover {
  border: 2px solid #2cae72;
}
.book_driver:hover {
  opacity: 0.9;
}
.disabledBtn {
  width: 282px;
  height: 67px;
  background-color: var(--nav-color);
  color: #ffffff;
  font-family: "Circular-Std-Book";
  font-size: var(--font-content-lottie);
  background-repeat: no-repeat;
  border: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.6);
}
.overlayTrack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
}
.form_number {
  z-index: 10;
}
.btn-app-download-mobile {
  display: none;
}
@media (min-width: 1280px) and (max-width: 1535px) {
  .wrapper-content-lottie {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: -7%;
  }
  .wrapper-content {
    width: 50%;
    height: auto;
  }
  .wrapper-lottie {
    width: 50%;
    height: auto;
  }
  .content_hire {
    width: 90%;
    height: auto;
    margin-left: 96px;
    margin-top: 150px;
  }
  .content_lottie {
    width: 80%;
    height: 537px;
    margin-top: 127px;
    margin-left: 50px;
  }
  .content_section_heading {
    font-size: var(--font-heading-lottie);
    font-family: "Circular-Std-Bold";
    color: var(--nav-color);
    margin-top: 20px;
    line-height: 58px;
    letter-spacing: -2px;
  }
  .content_subsection {
    font-size: 1.049em;
    font-family: "Circular-Std-Medium";
    color: #127e40;
  }
  .content_subsection_heading {
    font-family: "Circular-Std-Book";
    color: rgba(9, 8, 26, 0.3);
    margin-top: 46px;
    font-size: var(--font-content-lottie);
  }
  .btn_download_bookDriver {
    margin-top: 47px;
  }
  .book_driver {
    max-width: 282px;
    height: 67px;
    background-color: var(--nav-color);
    color: #ffffff;
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
  .download_app {
    max-width: 208px;
    height: 67px;
    color: var(--nav-color);
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-color: #ffffff;
    margin-left: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 2px solid var(--nav-color);
  }
  .btn-app-download-mobile {
    display: none;
  }
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .wrapper-content-lottie {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: -8%;
  }
  .wrapper-content {
    width: 50%;
    height: auto;
  }
  .wrapper-lottie {
    width: 50%;
    height: auto;
  }
  .content_hire {
    width: 100%;
    height: auto;
    margin-left: 96px;
    margin-top: 150px;
  }
  .content_lottie {
    width: 80%;
    height: 537px;
    margin-top: 127px;
    margin-left: 50px;
  }
  .content_section_heading {
    font-size: var(--font-heading-lottie);
    font-family: "Circular-Std-Bold";
    color: var(--nav-color);
    margin-top: 20px;
    line-height: 58px;
    letter-spacing: -2px;
  }
  .content_subsection_heading {
    font-family: "Circular-Std-Book";
    color: rgba(9, 8, 26, 0.3);
    margin-top: 46px;
    font-size: var(--font-content-lottie);
  }
  .btn_download_bookDriver {
    margin-top: 47px;
  }
  .book_driver {
    max-width: 32%;
    height: 50px;
    background-color: var(--nav-color);
    color: #ffffff;
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
  .download_app {
    max-width: 30%;
    height: 50px;
    color: var(--nav-color);
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-color: #ffffff;
    margin-left: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 2px solid var(--nav-color);
  }
  .btn-app-download-mobile {
    display: none;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .wrapper-content-lottie {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .wrapper-content {
    width: 80%;
    height: auto;
    margin: auto;
  }
  .wrapper-lottie {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .content_hire {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 90px;
  }
  .content_lottie {
    width: 70%;
    height: auto;
    margin: auto;
    margin-top: 70px;
  }
  .content_section_heading {
    font-size: var(--font-heading-lottie);
    font-family: "Circular-Std-Bold";
    color: var(--nav-color);
    line-height: 58px;
    letter-spacing: -2px;
    text-align: center;
  }
  .content_subsection_heading {
    font-family: "Circular-Std-Book";
    color: rgba(9, 8, 26, 0.3);
    font-size: var(--font-content-lottie);
    text-align: center;
  }
  .content_subsection {
    text-align: center;
  }
  .btn_download_bookDriver {
    margin-top: 47px;
    text-align: center;
  }
  .book_driver {
    max-width: 32%;
    height: 50px;
    background-color: var(--nav-color);
    color: #ffffff;
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
  .download_app {
    max-width: 30%;
    height: 50px;
    color: var(--nav-color);
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie);
    background-color: #ffffff;
    margin-left: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 2px solid var(--nav-color);
  }
  .btn-app-download-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .wrapper-content-lottie {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .wrapper-content {
    width: 80%;
    height: auto;
    margin: auto;
    /* border: 2px solid red; */
  }
  .wrapper-lottie {
    width: 90%;
    height: auto;
    margin: auto;
    /* border: 2px solid red; */
  }
  .content_hire {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 90px;
  }
  .content_lottie {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 30px;
    /* border: 2px solid green; */
  }
  .content_section_heading {
    font-size: var(--font-heading-lottie);
    font-family: "Circular-Std-Bold";
    color: var(--nav-color);
    line-height: 28px;
    letter-spacing: -2%;
    text-align: center;
  }
  .content_subsection_heading {
    font-family: "Circular-Std-Book";
    color: rgba(9, 8, 26, 0.3);

    font-size: var(--font-content-lottie);
    text-align: center;
    margin-top: 14px;
  }
  .content_subsection {
    text-align: center;
    margin-top: -20px;
    letter-spacing: 4%;
  }
  .btn_download_bookDriver {
    display: none;
  }
  .btn-app-download-mobile {
    margin-top: 8%;
    display: Block;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .book_now_mobile {
    max-width: 60%;
    height: 5vh;
    color: #ffffff;
    background: #09081a;
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie-download);
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    border: 1px solid var(--nav-color);
    box-sizing: border-box;
    width: 136px;
  }
  .download_app_mobile {
    max-width: 60%;
    height: 5vh;
    color: white;
    font-family: "Circular-Std-Book";
    font-size: var(--font-content-lottie-download);
    color: #09081a;
    background: #ffffff;
    /* margin-left: 20px; */
    background-repeat: no-repeat;
    cursor: pointer;
    border: 1px solid var(--nav-color);
    box-sizing: border-box;
    width: 136px;
  }
}
@media (min-width: 1536px) {
  .wrapper-content-lottie {
    margin: auto;
  }
}
