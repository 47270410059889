* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --nav-color: #09081a;
  --nav-font-size: clamp(0.5rem, 1.111vw, 1rem);
  --nav-font-size-midsize: clamp(0.75rem, 1.111vw, 1rem);
}
.nav-container {
  position: relative;
  z-index: 999;
  background-color: white;
  width: 100%;
  height: 103px;
  border-bottom: 1px solid #e5e5e5;
}
.sticky-nav {
  max-width: 100%;
  position: fixed;
  transition: all;
  top: 0;
  left: 0;
}
.nav-head {
  width: 100%;
  height: 100%;
  display: flex;
}
.driveu-logo {
  width: 114px;
  height: 34px;
  margin-left: 97px;
  margin-top: 34px;
  cursor: pointer;
}
.nav-options {
  margin-left: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.nav-select {
  color: var(--nav-color);
  font-family: "Circular-Std-Medium";
  font-size: var(--nav-font-size);
  cursor: pointer;
  text-decoration: none;
}
.navbar-options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-download-now {
  width: 183px;
  height: 52px;
  background-color: var(--nav-color);
  color: #ffffff;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}
.nav-down-arrow {
  cursor: pointer;
  margin-top: 2px;
}
.nav-select:hover {
  color: #2cae72;
}
.nav-download-now:hover {
  opacity: 0.9;
  color: white;
  text-decoration: none;
}
.hamburger-menu {
  display: none;
}
.navbar-options-mobile {
  display: none;
}
.nav-download-app {
  color: #ffffff;
  text-decoration: none;
}
.mobile-nav-options,
.mobile-dropdown {
  display: none;
}
.nav-rotate-arrow {
  transform: rotate(180deg);
}

@media (min-width: 1280px) and (max-width: 1535px) {
  .nav-container {
    width: 100%;
    max-width: 90em;
    height: 103px;
    border-bottom: 1px solid #e5e5e5;
  }

  .nav-container {
    position: relative;
    z-index: 999;
    background-color: white;
  }
  .sticky-nav {
    max-width: 100%;
    height: 103px;
    position: fixed;
    transition: all;
    top: 0;
    left: 0;
    transition: all 0.5s ease-in;
    transform: translateY(-5px);
  }
  .nav-head {
    max-width: 100%;
    max-height: 100%;
    display: flex;
  }
  .nav-head {
    max-width: 100%;
    max-height: 100%;
    display: flex;
  }
  .nav-options {
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }
  .driveu-logo {
    width: 114px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 70px;
    margin-top: 34px;
    cursor: pointer;
  }

  .nav-select {
    color: var(--nav-color);
    font-family: "Circular-Std-Medium";
    font-size: var(--nav-font-size);
    cursor: pointer;
    text-decoration: none;
  }
  .nav-download-now {
    width: 183px;
    height: 52px;
    background-color: var(--nav-color);
    color: #ffffff;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .nav-container {
    width: 100%;
    max-width: 90em;
    height: 103px;
    border-bottom: 1px solid #e5e5e5;
  }

  .nav-container {
    position: relative;
    z-index: 999;
    background-color: white;
  }
  .sticky-nav {
    position: fixed;
    transition: all;
    top: 0;
    left: 0;
    transition: all 0.5s ease-in;
    transform: translateY(-5px);
  }
  .nav-head {
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .nav-options {
    margin-left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding: 20px;
  }

  .nav-select {
    color: var(--nav-color);
    font-family: "Circular-Std-Medium";
    font-size: var(--nav-font-size);
    cursor: pointer;
    text-decoration: none;
  }
  .nav-download-now {
    width: 123px;
    height: 40px;
    background-color: var(--nav-color);
    color: #ffffff;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .nav-container {
    width: 100%;
    max-width: 90em;
    height: 103px;
    border-bottom: 1px solid #e5e5e5;
  }
  .driveu-logo {
    width: 70px;
    height: 50%;
    margin: 30px 0 0 20px;
  }
  .nav-container {
    position: relative;
    z-index: 999;
    background-color: white;
  }
  .sticky-nav {
    position: fixed;
    transition: all;
    top: 0;
    left: 0;
    transition: all 0.5s ease-in;
    transform: translateY(-5px);
  }
  .nav-head {
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .nav-options {
    margin-left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 54px;
    padding: 20px;
  }

  .nav-select {
    color: var(--nav-color);
    font-family: "Circular-Std-Medium";
    font-size: 11px;
    cursor: pointer;
    text-decoration: none;
  }
  .nav-download-now {
    width: 123px;
    height: 40px;
    background-color: var(--nav-color);
    color: #ffffff;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .mobile-dropdown {
    display: block;
  }
  .hamburger-menu {
    display: block;
    font-size: 2rem;
    transition: all 2s linear;
    width: 8%;
    height: fit-content;
  }
  .nav-container {
    min-width: 100%;
    max-width: 90em;
    height: 55px;
    border-bottom: 1px solid #e5e5e5;
  }
  .nav-head {
    display: none;
  }
  .nav-options {
    display: none;
  }
  .nav-head-mobile {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  #driveu-logo-mobile {
    display: block;
    min-width: 80px;
    width: 10%;
    cursor: pointer;
    margin-left: 0px;
    margin-top: -5px;
  }
  .main-logo {
    width: 100%;
  }
  .mediaicon {
    min-width: 25px;
    width: 100%;
    margin-top: 8px;
  }
  .navbar-options-mobile {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    min-width: 30%;
    width: 40%;
    height: 60%;
    /* border: 1px solid red; */
  }
  .nav-download-now-mobile {
    /* min-width: 20%; */
    /* width: 25%; */
    height: 100%;
    background: #ffffff;
    text-align: center;
    /* margin-top: 4px; */
    margin-right: 5px;
    cursor: pointer;
  }
  .download-mobile {
    color: #000000;
    min-width: 50px;
    max-width: 60px;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .nav-phone-mobile {
    min-width: 20%;
    width: 25%;
    height: 100%;
    background: #ffffff;
    margin-top: 4px;
    text-align: center;
  }
  .phone-mobile {
    min-width: 20%;
    width: 100%;
    height: auto;
    min-width: 50px;
    max-width: 60px;
    text-align: center;
  }
  .mobile-nav-options {
    display: block;
    display: flex;
    flex-direction: column;
    border: 2px solid #e5e5e5;
    position: absolute;
    top: 100%;
    left: 0%;
    z-index: 20;
    background-color: white;
    opacity: 1;
    width: 100%;
    margin: auto;
    height: auto;
    gap: 32px;
    align-items: flex-start;
    display: none;
  }
  #nav-download-mobile {
    display: none;
  }
  .ham-close-menu {
    margin-left: 15px;
    margin-top: 2px;
  }
  #nav-down-arrow {
    margin-right: 20px;
  }
  #nav-arrow-about {
    margin-bottom: 30px;
    margin-right: 20px;
  }
  #navbar-options-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .nav-home {
    margin-top: 20px;
  }
  .nav-about-us {
    margin-bottom: 30px;
  }
  .nav-select {
    font-size: 16px;
    margin-left: 20px;
  }
}
@media (min-width: 1536px) {
  .nav-container {
    margin: auto;
    width: 100%;
  }
}
