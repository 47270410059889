.carServiceCoupanMainContainer {
  height: 20%;
  background-color: white;
  margin: auto;
  margin-top: 4%;
  /* border: 2px solid pink; */
  /* margin-bottom: 3rem; */
}

.infoHeadingMainContainer {
  /* border: 1px solid teal; */
  height: auto;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
}
.infoHeadingContainer {
  /* border: 1px solid teal; */
  height: 45%;
}
.infoHeading {
  font-size: 14px;
  font-weight: 700;
  color: #09081a;
}
.infoSubHeadingPriceContainer {
  font-size: 10px;
  font-weight: 400;
}
.infoSubHeadingMandatoryContainer {
  font-size: 10px;
  font-weight: 400;
}
.infoSubHeading {
  font-size: 10px;
  font-weight: 400;
  color: #9d9ca3;
}
.infoSubHeadingContainer {
  /* border: 1px solid green; */
  height: 45%;
}

/*  Promo Code Css */

.infoPromoMainContainer {
  width: 95%;
  display: flex;
  flex-direction: row;
  color: #09081a;
  gap: 5%;
  padding-top: 0.6rem;
  /* border: 2px solid teal; */
  /* background-color: #9d9ca3; */
}
.confirmButton {
  width: 98%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  color: #09081a;
  gap: 5%;
  /* border: 1px solid red; */
}
.infoPromoInput {
  border: 2px solid rgba(9, 8, 26, 0.04);
  background: rgba(9, 8, 26, 0.04);
  border-radius: 10px;
  width: 70%;
}

.infoPromoButtonMainContainer {
  width: 25%;
  display: flex;
}
.carServicebutton {
  width: 100%;
  /* margin-bottom: 1rem; */
  border: 1px solid black;
}
.infoPromoButtonContainer {
  border: 2px solid #f93356;
  background-color: #f93356;
  color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.infoPromoButtonContainer:hover {
  background-color: #f93356;
}
input[type="text"]:focus {
  outline-offset: 0px !important;
  --tw-ring-color: transparent !important;
  border-color: transparent;
  border: 2px solid rgba(9, 8, 26, 0.04);
  background: rgba(9, 8, 26, 0.04);
}

.successPromo {
  color: #f93356;
  width: 95%;
  margin: auto;
  margin-top: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.mainModal {
  background-color: white;
  /* padding: 0.5rem; */
  width: 100%;
}

.modalBody {
  width: 95%;
  margin: auto;
}
.mainModal header button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 25px;
  width: 25px;
  /* background-image: url("../../PNGImage/closebutton.png"); */
  display: block;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 25px;
}
