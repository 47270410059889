.privacy-policy-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  color: #444545;
  font-family: "Circular-Std-Book";
  margin-bottom: 8rem;
}
.TANDCwrapper {
  max-width: 1170px;
  margin: 0 auto;
  color: #444545;
  font-family: "Circular-Std-Book";
  margin-bottom: 8rem;
}
.TANDCwrapper span.bold {
  font-weight: 400;
  font-family: "Circular-Std-Book";
}
.termHead {
  color: #2cae72;
  font-size: 24px;
  font-weight: 500;
  margin: 40px 0 20px;
  text-transform: uppercase;
  font-family: "Circular-Std-Bold";
}

.TermConditionTable td {
  border: 1px solid black;
  border-collapse: collapse;
}
.TANDCwrapper p {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 150%;
  font-family: "Circular-Std-Book";
}
.TANDCwrapper .sub-head {
  text-transform: uppercase;
  margin: 40px 0 10px;
  font-weight: 500;
  font-family: "Circular-Std-Bold";
  /* border: 1px solid red; */
  padding: 10px;
  box-sizing: border-box;
}
.NumberText {
  /* border: 1px solid red; */
  text-align: left;
  margin-top: 2rem;
  /* padding-left: 2rem; */
  /* padding-bottom: 0.5rem; */
}

/* ===== new css start here ======*/

.definitionList > ol,ul{
  list-style-type: lower-alpha; 
  padding-left: 40px;
}
.definitionList > ol,ul li {
  font-size: 14px;
  line-height: 1.6rem;
}

table tr th,td{
  font-size: 14px;
}
/* ===== new css end here ======*/

@media only screen and (max-width: 768px) {
  .TANDCwrapper {
    margin-bottom: 3rem;
    width: 80%;
  }
  .TermConditionTable {
    width: 40%;
  }
  .termHead {
    font-size: 21px;
  }
  .NumberText{
    padding-left: 0;
  }
  .TANDCwrapper .sub-head{
    padding-left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .TANDCwrapper {
    margin-left: 2.5rem;
  }
  .NumberText{
    padding-left: 0;
  }
  .TANDCwrapper .sub-head{
    padding-left: 0;
  }
}
