:root {
    --font-size-HappyDriverUHead: clamp(1.375em, 2.778vw, 2.5em);
    --font-size-HappyDriverMedium: 17px;
    --font-size-HappyDriverSmall: 15px;
  }
  .happy_driver_main_div {
    width: 75%;
    margin: auto;
    border-bottom: 1px solid gray;
    display: flex;
    gap: 0%;
    flex-direction: column;
    padding-bottom: 10%;
  }
  .happy_driver_heading_text {
    text-align: center;
    font-weight: 700;
    font-size: var(--font-size-HappyDriverUHead);
    font-family: "Circular-Std-Bold";
    padding: 5%;
  }
  .happy_driver_subHeading_text {
    text-align: center;
    font-family: "Circular-Std-Book";
    font-weight: 450;
    color: rgba(9, 8, 26, 0.3);
    font-size: var(--font-size-HappyDriverMedium);
    padding-bottom: 10%;
  }
  .testimonial {
    margin: auto;
    display: flex;
    flex-direction: row;
  }
  
  .first_testimonial,
  .sec_testimonial,
  .thi_testimonial {
    width: 32%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    text-align: left;
    margin-left: 2%;
    box-shadow: 0px 3.71616px 29.7293px rgba(25, 32, 44, 0.08);
  }
  
  .first_testimonial_first,
  .sec_testimonial_first,
  .thi_testimonial_first {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .first_testimonial_img,
  .sec_testimonial_img,
  .thi_testimonial_img {
    width: 17%;
    height: 18%;
    margin: 5%;
    margin-left: 5%;
  }
  .first_testimonial_img > img,
  .sec_testimonial_img > img,
  .thi_testimonial_img > img {
    max-width: 100%;
    border-radius: 25px;
  }
  .first_testimonial_text,
  .sec_testimonial_text,
  .thi_testimonial_text {
    border: none;
    margin-top: 4%;
    border-bottom: 1px solid rgba(9, 8, 26, 0.3);
    height: 20vh;
    width: 90%;
    margin: auto;
    margin-bottom: 7%;
    border-radius: 2px;
    font-family: "Circular-Std-Book";
    font-size: var(--font-size-HappyDriverSmall);
    color: rgba(9, 8, 26, 0.3);
    font-weight: 450;
    text-align: left;
  }
  
  .first_testimonial_sec,
  .sec_testimonial_sec,
  .thi_testimonial_sec {
    margin-left: 3%;
    margin-bottom: 8%;
    font-family: "Circular-Std-Book";
    font-size: var(--font-size-HappyDriverSmall);
    font-weight: 500;
    color: rgba(9, 8, 26, 0.3);
    opacity: 1 !important;
  }
  .slash {
    color: #05d585;
    font-weight: 700 !important;
    font-family: "Circular-Std-Book";
  }
  @media (min-width: 1023px) and (max-width: 1220px) {
    .happy_driver_main_div {
      width: 90%;
      margin: auto;
      border-bottom: 1px solid gray;
  
      display: flex;
      gap: 0%;
      flex-direction: column;
      padding-bottom: 10%;
    }
  }
  @media (min-width: 767px) and (max-width: 1023px) {
    .happy_driver_main_div {
      width: 90%;
      height: 100%;
      margin: auto;
      border-bottom: 1px solid gray;
  
      display: flex;
      flex-direction: column;
      padding-bottom: 10%;
    }
    .happy_driver_heading_text {
      width: 100%;
      height: 10%;
      text-align: center;
      font-weight: 700;
      font-size: var(--font-size-HappyDriverUHead);
      font-family: "Circular-Std-Bold";
      padding: 3%;
    }
    .happy_driver_subHeading_text {
      text-align: center;
      font-family: "Circular-Std-Book";
      font-weight: 450;
      color: rgba(9, 8, 26, 0.3);
      font-size: var(--font-size-HappyDriverMedium);
      padding-bottom: 5%;
    }
    .testimonial {
      margin: auto;
      display: flex;
      justify-content: center;
      height: 100%;
    }
  
    .first_testimonial,
    .sec_testimonial,
    .thi_testimonial {
      width: 32%;
      height: auto;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      text-align: left;
      margin-left: 2%;
      box-shadow: 0px 3.71616px 29.7293px rgba(25, 32, 44, 0.08);
    }
    .first_testimonial_text,
    .sec_testimonial_text,
    .thi_testimonial_text {
      height: 100%;
    }
    .first_testimonial_sec,
    .sec_testimonial_sec,
    .thi_testimonial_sec {
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    .happy_driver_main_div {
      width: 90%;
      height: 100%;
      margin: auto;
      border-bottom: 1px solid gray;
      display: flex;
      flex-direction: column;
      padding-bottom: 15%;
      margin-top: 13%;
    }
    .happy_driver_heading_text {
      width: 100%;
      height: 10%;
      text-align: center;
      font-weight: 700;
      font-size: var(--font-size-HappyDriverUHead);
      font-family: "Circular-Std-Bold";
      padding: 3%;
    }
    .happy_driver_subHeading_text {
      text-align: center;
      font-family: "Circular-Std-Book";
      font-weight: 450;
      color: rgba(9, 8, 26, 0.3);
      font-size: var(--font-size-HappyDriverMedium);
      padding-bottom: 5%;
    }
    .testimonial {
      margin: auto;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-gap: 1%;
      justify-content: center;
      height: 100%;
    }
  
    .first_testimonial,
    .sec_testimonial,
    .thi_testimonial {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      text-align: left;
      box-shadow: 0px 3.71616px 29.7293px rgba(25, 32, 44, 0.08);
    }
    .first_testimonial_text,
    .sec_testimonial_text,
    .thi_testimonial_text {
      height: 100%;
    }
    .first_testimonial_sec,
    .sec_testimonial_sec,
    .thi_testimonial_sec {
      font-size: 15px;
    }
  }
  