.stateMainContainerHeading {
  /* border: 2x solid red; */
  font-size: 18px;
  font-family: "Circular-Std-medium";
}
.stateInputMainContainer {
  border: 1px solid gray;
  background-color: white;
  /* border: 2px solid red; */
  width: 100%;
  /* height: 12%; */
  border-radius: 5px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.stateInputSearch {
  font-size: 18px;
}

.stateInputMainContainer > input {
  border: none;
  font-family: "Circular-Std-Book";
  outline: none;
  width: 90%;
  font-size: 18px;
  background-color: white;
  /* border: 2px solid teal; */
}
.stateInputMainContainer > input:focus {
  border: none;
  background-color: white;
}

input[type="text"]:focus {
  background-color: white;
  border: none;
}

/* input[type="text"]:focus {
  outline-offset: 0px !important;
  --tw-ring-color: transparent !important;
  border-color: transparent;
  border: none;
  background-color: white;
  border: 1px solid red;
} */

.stateOptionMainContainer {
  margin-top: 1.5rem;
  height: 350px;
  width: 100%;
  overflow: scroll;
}
.stateOptionContainer {
  padding: 1rem;
  font-size: 16px;
  font-family: "Circular-Std-Book";
  color: black;
  /* border-bottom: 1px solid #888; */
  border-bottom: 1.5px solid rgba(9, 8, 26, 0.08);
}
