.custom-datepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dateLangDiv {
  width: 100%;
  color: #000;
  font-weight: 600;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.localDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -3px;
}
