:root {
  --font-Circular-Bold: "Circular-Std-Bold";
  --font-Circular-Medium: "Circular-Std-Medium";
  --font-Circular-Book: "Circular-Std-Book";
}
.services-wrapper {
  max-width: 1015px;
  /* max-height: 373px; old height */
  max-height: 300px;
  width: 70%;
  /* height: 373px; old height */
  height: 300px;
  margin: auto;
  margin-top: 1rem;
  background: #ffffff;
  position: absolute;
  top: 100%;
  background: #ffffff;
  border: 0.5px solid rgba(9, 8, 26, 0.2);
  box-shadow: 4px 4px 44px rgba(0, 5, 46, 0.08);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.services-content-box {
  width: 94%;
  /* height: 78.5%; old with rsa */
  height: 78.5%;
  display: grid;
  grid-template-columns: repeat(3, 27.7%);
  grid-gap: 16.9% 8.55%;
}
.main-services {
  width: auto;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
  cursor: pointer;
}
.services-svg {
  width: 52px;
  height: 52px;
}
.main-services:hover .svg-option {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.services-dd-content {
  width: 187px;
  height: 64px;
}
.article-services {
  font-size: 14px;
  font-family: var(--font-Circular-Book);
  opacity: 0.4;
  font-weight: 450;
  line-height: 18px;
  color: #09081a;
}
.section-services {
  font-family: var(--font-Circular-Medium);
  font-weight: 500;
  color: #09081a;
  line-height: 20px;
  font-size: 16px;
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .services-content-box {
    width: 94%;
    height: 78.5%;
    display: grid;
    grid-template-columns: repeat(3, 27.7%);
    grid-gap: 16.9% 8.55%;
  }
  .main-services {
    width: auto;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 19px;
  }
  .services-svg {
    width: 52px;
    height: 52px;
  }
  .services-dd-content {
    width: 187px;
    height: 64px;
  }
  .article-services {
    font-size: 11px;
    font-family: var(--font-Circular-Book);
    opacity: 0.4;
    font-weight: 450;
    line-height: 18px;
    color: #09081a;
  }
  .section-services {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 12px;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .services-wrapper {
    width: 55%;
    height: 280px;
  }
  .services-content-box {
    width: 94%;
    height: 85%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 29%);
    grid-gap: 8% 6.55%;
  }
  .main-services {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .main-services:hover .svg-option {
    width: 57px;
    height: 57px;
  }
  .services-dd-content {
    width: 187px;
    display: flex;
    align-items: center;
  }
  .article-services {
    display: none;
  }
  .section-services {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 12px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .services-wrapper {
    width: 55%;
    height: 280px;
  }
  .services-content-box {
    width: 94%;
    height: 85%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 29%);
    grid-gap: 8% 6.55%;
  }
  .main-services {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .services-svg {
    width: 48px;
    height: 48px;
  }
  .services-dd-content {
    width: 187px;
    display: flex;
    align-items: center;
  }
  .article-services {
    display: none;
  }
  .section-services {
    font-family: var(--font-Circular-Medium);
    font-weight: 500;
    color: #09081a;
    line-height: 20px;
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .services-wrapper {
    width: 100%;
    height: 35vh;
    border-radius: 10px;
    margin-top: 0.5rem;
    margin-left: 10px;
  }
  .services-content-box {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 3% 3%;
    overflow: scroll;
  }
  .main-services {
    width: 100%;
    min-height: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .services-svg {
    max-width: 100%;
    width: 40%;
    height: 100%;
  }
  .svg-option {
    width: 100%;
    height: 100%;
  }
  .services-dd-content {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }
  .article-services {
    display: none;
  }
  .section-services {
    font-family: var(--font-Circular-Book);
    font-weight: 700;
    color: #09081a;
    line-height: 10px;
    font-size: 10px;
    text-align: left;
  }
}
