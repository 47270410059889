* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.hamburgur-booking-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
}
.Mobile_Option_booking {
  width: 100%;
  height: auto;
  display: grid;
  row-gap: 20px;
}
.MobileNav_Heading_Booking {
  user-select: none;
  cursor: pointer;
  border-bottom: 2px solid gray;
  line-height: 300%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Ham-subHeading-booking {
  font-size: 21px;
  font-family: "Circular-Std-Medium";
  font-weight: 400;
}
.color-change-booking {
  font-size: 21px;
  font-family: "Circular-Std-Bold";
  color: #09081a;
  outline: none;
  opacity: 0.8;
  width: 100%;
}

.nav-rotate-arrow {
  transform: rotate(180deg);
  margin-right: 1.5%;
  position: relative;
  top: -44%;
}
.arrow {
  margin-right: 1.5%;
}
.Hover-effect-ham {
  z-index: 30;
}
.Hover-effect-ham > .color-change:hover {
  color: #127e40;
}

.Ham-subHeading-booking {
  font-family: "Circular-Std-Medium";
  -webkit-font-family: "Circular-Std-Medium";
  color: #09081a;
}
.Ham-subHeading-content {
  width: 240%;
  z-index: 1;
  user-select: none;
  font-family: "Circular-Std-Book";
}
.color-change > div {
  font-size: 16px;
  color: #09081a;
  opacity: 0.7;
  font-family: "Circular-Std-Book";
}
