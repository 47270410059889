.cardContainer {
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  overflow-y: scroll;
  height: fit-content;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 96%;
  /* border: 1px solid red; */
}
.mapCardScroll {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  overflow: scroll;
  /* border: 1px solid green; */
  /* margin-right: 2rem; */
  padding-right: 1rem;
}
