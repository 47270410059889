:root {
  --font-size-HcontentHead: clamp(1.5em, 2.569vw, 2.313em);
  --font-size-HcontentContent: clamp(1rem, 1.181vw, 1.063rem);
}
.Hyderabad_Content_main_div {
  width: 86%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  padding-top: 9%;
  padding-bottom: 2%;
}
.Hyderabad_Content_main_div_Heading {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: var(--font-size-HcontentHead);
  margin-bottom: 5%;
  font-family: "Circular-Std-Bold";
  font-weight: 700;
}
.Hyderabad_Content_main_div_content {
  font-size: var(--font-size-HcontentContent);
  color: rgba(9, 8, 26, 0.3);
  text-align: center;
  font-family: "Circular-std-book";
  font-weight: 450;
}
