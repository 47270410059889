.first_Input_Tag_Div {
  display: flex;
  flex-direction: row;
}
.empty_input_div {
  height: 1rem;
  width: 1rem;
  background-color: white;
  margin-top: 0.7rem;
  border-radius: 2px;
  font-size: 1.3rem;
  margin-left: -1.5rem;
}
.suggestion:hover {
  background-color: #127e40;
  color: white;
  border-radius: 5px;
}
.debounceBox {
  background-color: white;
  border: 0.476142px solid rgba(9, 8, 26, 0.2);
  box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
  border-radius: 5px;
  color: #666;
  position: absolute;
  z-index: 1000;
  margin-top: 0.5rem;
}
.location {
  font-size: 14px !important;
  border-radius: 10px !important;
  border: 0.2px solid #888;
  font-family: "Circular-Std-Book";
}
.location:focus {
  border: 0.2px solid #888;
}
.location::placeholder {
  font-size: 14px;
}
