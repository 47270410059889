.roundsuggestion:hover {
  background-color: #127e40;
  color: white;
  border-radius: 5px;
}
.roundWay_Input_Tag_Div {
  display: flex;
  flex-direction: row;
}
.round_empty_input_div {
  height: 1rem;
  width: 1rem;
  background-color: white;
  margin-top: 0.7rem;
  border-radius: 2px;
  font-size: 1.3rem;
  margin-left: -1.5rem;
}
.roundsuggestion {
  cursor: pointer;
}
.secondInputLocation {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 767px) {
  .secondInputLocation {
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;
  }
}
