.otp_popup {
  width: 45%;
  height: 640px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  opacity: 1;
  background-color: white;
  z-index: 2000;
  background: #ffffff;
  border: 0.476142px solid rgba(9, 8, 26, 0.2);
  box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
  border-radius: 22.8548px;
}
.content-phone-verify {
  width: 85%;
  margin: 60px auto;
}
.top-content-verify {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.otp-verification {
  width: 85%;
  margin: auto;
}
.otp_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
h2 {
  text-align: center;
  font-family: "Circular-Std-Bold";
  margin-top: 25px;
}
h3 {
  text-align: center;
  color: rgba(9, 8, 26, 0.3);
  font-family: "Circular-Std-Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
}
input[type="text"] {
  width: 100%;
  height: 43px;
  color: #333;
  font-size: 16px;
  transition: all 0.5s ease-in;
  outline: none;
  padding: 10px;
}
.verify-otp {
  background-color: #000000;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: "Circular-Std-Medium";
  width: 100%;
  height: 43px;
  padding: 8px;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-otp:hover {
  opacity: 0.9;
}
input[type="text"]:focus {
  border: 2px solid black;
  background-color: #ffffff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* input[type="text"] {
  -moz-appearance: textfield;
} */
.err_msg_mobile {
  width: 280px;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  color: rgba(193, 0, 0, 0.7);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px;
}
.resend_otp {
  background-color: #ffffff;
  text-align: center;
  font-family: "Circular-Std-Medium";
  color: #127e40;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #127e40;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  height: 43px;
  padding: 8px;
  background-repeat: no-repeat;
}
.resend_otp:hover {
  opacity: 0.8;
}
.resend_otp_gray {
  color: gray;
  border: 2px solid gray;
  background-color: #ffffff;
  text-align: center;
  font-family: "Circular-Std-Medium";
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  height: 43px;
  padding: 8px;
  background-repeat: no-repeat;
}
#closeOtp {
  width: 100%;
}
.PopUpClose {
  font-size: 40px;
  color: white;
  background: #000000;
  border-radius: 50px;
  float: right;
  margin-top: -15px;
  margin-right: -15px;
}

.otpimg1 {
  width: 20%;
}
.changeNum {
  text-decoration: underline;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  text-align: center;
  color: rgba(9, 8, 26, 0.3);
}
.try_again_options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  margin-top: 1rem;
}

.number-enter {
  width: 100%;
}
.content-otpnum {
  display: flex;
  /* border:10px solid green; */
  flex-direction: column;
  justify-content: center;
}
.err_msg_otp {
  text-transform: uppercase;
  width: 100%;
  margin: auto;
  color: rgba(193, 0, 0, 0.7);
  font-family: "Circular-Std-Book";
  text-align: center;
  font-size: 14px;
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .otp_popup {
    width: 45%;
    height: 580px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
  .err_msg_otp {
    font-size: 10px;
    margin-top: -24px;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .otp_popup {
    width: 52%;
    height: 570px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
  .content-phone-verify {
    /* border:5px solid blue; */
    width: 85%;
    margin: 30px auto;
  }
  .err_msg_otp {
    font-size: 10px;
    margin-top: -20px;
  }
  .top-content-verify {
    width: 100%;
    display: flex;
    /* margin-top:0px; */
    flex-direction: column;
    justify-content: center;
    /* align-content:center; */
  }
  .otpimg1 {
    width: 22%;
  }
}
@media (max-width: 540px) {
  .otp_popup {
    height: 490px;
    width: 95%;
  }
  .err_msg_otp {
    font-size: 10px;
    margin-top: 0px;
  }
  .content-phone-verify {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .otpimg1 {
    min-width: 80px;
  }
  h2 {
    text-align: center;
    font-family: "Circular-Std-Bold";
    margin-top: 10px;
    font-size: 18px;
  }
  .otp-verification {
    width: 100%;
  }
  .resend_otp,
  .resend_otp_gray,
  .verify-otp,
  .otp_block input[type="text"] {
    width: 80%;
    height: 40px;
    text-align: center;
    font-size: 12px;
  }
  .try_again_options {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 5px;
  }
}
@media (min-width: 541px) and (max-width: 767px) {
  .otp_popup {
    height: 490px;
    width: 63%;
  }
  .err_msg_otp {
    font-size: 10px;
    margin-top: 0px;
  }
  .content-phone-verify {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .otpimg1 {
    min-width: 80px;
  }
  h2 {
    text-align: center;
    font-family: "Circular-Std-Bold";
    margin-top: 10px;
    font-size: 18px;
  }
  .otp-verification {
    width: 100%;
  }
  .resend_otp,
  .resend_otp_gray,
  .verify-otp,
  .otp_block input[type="text"] {
    width: 80%;
    height: 40px;
    text-align: center;
    font-size: 12px;
  }
  .try_again_options {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 5px;
  }
}
