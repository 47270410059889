.regi-popup {
  /* width: 45%; */
  width: 40%;
  height: 555px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 1;
  z-index: 2000;
  background: #ffffff;
  border: 0.476142px solid rgba(9, 8, 26, 0.2);
  box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
  border-radius: 22.8548px;
}
.numberBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.numberBlock > input[type="text"] {
  width: 100%;
  height: 43px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 16px;
  transition: all 0.5s ease-in;
  outline: none;
  padding: 10px;
}
.contBtn {
  background-color: #000000;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: "Circular-Std-Medium";
  width: 100%;
  height: 43px;
  padding: 8px;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}
.contBtn:hover {
  opacity: 0.9;
}
.numberBlock > input[type="text"]:focus {
  border: 2px solid black;
  background-color: #ffffff;
}
.numberBlock > input::-webkit-outer-spin-button,
.numberBlock > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberBlock > input[type="text"] {
  -moz-appearance: textfield;
}
.errMsg_mobile {
  width: 100%;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  color: rgba(193, 0, 0, 0.7);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px;
}
.content-phone {
  width: 90%;
  margin: auto;
}
.close {
  width: 100%;
}
.closeBtn {
  font-size: 45px;
  color: white;
  background: #000000;
  border-radius: 50px;
  float: right;
  margin-top: -15px;
  margin-right: -15px;
}
.top-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.otpimg {
  width: 20%;
}
#phone-number {
  text-align: center;
  font-family: "Circular-Std-Bold";
  margin-top: 20px;
}
.number-enter {
  width: 100%;
}
.enterNumber {
  width: 80%;
  height: 100%;
  margin: auto;
  margin-top: 20px;
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .regi-popup {
    /* width: 45%; */
    width: 40%;
    height: 450px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .regi-popup {
    /* width: 45%; */
    width: 50%;
    height: 450px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
  .top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #phone-number {
    text-align: center;
    font-family: "Circular-Std-Bold";
    margin-top: 20px;
    font-size: 19px;
  }
}
@media (max-width: 540px) {
  .regi-popup {
    width: 90%;
    height: 380px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
  .top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #phone-number {
    text-align: center;
    font-family: "Circular-Std-Bold";
    margin-top: 20px;
    font-size: 1rem;
  }
  .otpimg {
    min-width: 60px;
  }
  .errMsg_mobile {
    font-size: 10px;
  }
}

@media (min-width: 541px) and (max-width: 767px) {
  .regi-popup {
    width: 60%;
    height: 380px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    border: 0.476142px solid rgba(9, 8, 26, 0.2);
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 22.8548px;
  }
  .top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #phone-number {
    text-align: center;
    font-family: "Circular-Std-Bold";
    margin-top: 20px;
    font-size: 1rem;
  }
  .otpimg {
    min-width: 60px;
  }
  .errMsg_mobile {
    font-size: 10px;
  }
}
