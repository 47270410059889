/* Module Css use For avoiding Global Scope and Local Scoping */
.dutrackContainer {
  width: 100%;
  height: fit-content;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.duTrackDetailsWrapper {
  width: 94%;
  margin: auto;
  margin-top: 0.5rem;
}
.orderIdShow {
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  padding: 0.75rem;
  margin-bottom: 30px;
}
.instructionDU {
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  padding: 0.75rem;
  margin-top: 30px;
}
.orderIdShow > div {
  color: #61616c;
  margin-top: 5px;
}
.duBookingDetailsWrapper {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  width: 100%;
}
.duBookingDetailsWrapper > section {
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid #e6e6e8;
  padding: 0.75rem;
}
.headerTrack {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  width: 94%;
  margin: auto;
  padding: 0.75rem;
  margin-top: 0.5rem;
}
.duBookingContainer {
  padding: 0.25rem;
}
.duBookDetailsBlock {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  padding: 0.25rem;
}
.driverDetailBlock {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  padding: 0.5rem;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
  width: 100%;
  flex-direction: column;
}
.driverDetailBlockOne {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}
.nameRating {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ratingBlock {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}
.callIconStyle {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #009d61;
  border-radius: 50%;
}
.cancelRedirectionContainer {
  margin-top: 30px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
