:root {
  --font-headingcontact-lottie: clamp(1.625rem, 3.611vw, 3.25rem);
  --font-contentcontact-lottie: clamp(0.938rem, 1.528vw, 1.375rem);
  --font-btncontact: clamp(1rem, 1.528vw, 1.375rem);
  --font-headingtitle: clamp(0.938rem, 1.25vw, 1.125rem);
}
.AboutUs_Container {
  width: 100%;
  max-width: 90em;
  height: 500px;
  margin: auto;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  justify-content: center;
  gap: 10%;
}
.AboutUs_LeftContainer {
  width: 40%;
  /* height: 70%; */
  margin-top: 7%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* border: 1px solid red; */
}
.AboutUs_RightContainer {
  width: 40%;
  /* height: 70%; */
  margin-top: 7%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* border: 1px solid red; */
  /* align-items: center; */
  justify-content: space-between;
}
.AboutUs_Heading {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-headingtitle);
  line-height: 21px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #127e40;
  margin-bottom: 1rem;
  font-family: "Circular-Std-Book";
}
.AboutUs_SubHeading {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-headingcontact-lottie);
  line-height: 58px;
  color: #09081a;
  margin-bottom: 1rem;
  font-family: "Circular-Std-Book";
}
.AboutUs_Content,
.RightAboutUs_Content {
  font-style: normal;
  font-weight: 450;
  font-size: var(--font-contentcontact-lottie);
  line-height: 150%;
  color: rgba(9, 8, 26, 0.3);
  margin-bottom: 3rem;
  font-family: "Circular-Std-Book";
}
.AboutUs_Button {
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.AboutUs_Email {
  cursor: pointer;
  font-size: var(--font-btncontact);
}
.AboutUs_Email,
.RightAboutUs_Email {
  background-color: #09081a;
  color: white;
  height: 100%;
  width: 80%;
  border: none;
  font-style: normal;
  font-weight: 450;
  font-size: var(--font-btncontact);
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  font-family: "Circular-Std-Book";
  margin-right: 1.2rem;
}

.AboutUs_call {
  color: #09081a;
  height: 100%;
  width: 80%;
  border: 1px solid #09081a;
  font-style: normal;
  font-weight: 450;
  font-size: var(--font-btncontact);
  line-height: 28px;
  text-align: center;
  background-color: white;
  font-family: "Circular-Std-Book";
  cursor: pointer;
}

@media (max-width: 768px) {
  .AboutUs_Container {
    flex-direction: column;
    height: auto;
    gap: 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .AboutUs_LeftContainer,
  .AboutUs_RightContainer {
    width: 80%;
    padding: 10%;
    margin: auto;
    background: #ffffff;
    box-shadow: 2.96623px 2.96623px 32.6285px rgba(0, 5, 46, 0.08);
    border-radius: 17.7974px;
  }
  .AboutUs_Button {
    height: 2rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .AboutUs_Container {
    height: auto;
    margin-bottom: 7rem;
  }
  .AboutUs_RightContainer,
  .AboutUs_LeftContainer {
    height: auto;
  }

  .AboutUs_Button {
    height: 3rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1535px) {
  .AboutUs_Container {
    height: auto;
    margin-bottom: 8rem;
  }
}
