.bookingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1.5rem;
}
.bookingTypeContainer {
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}
.bookingTextWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}
.dailyTripType > div > span {
  font-size: 12px;
}
.dailyTripType > div > strong {
  font-size: 13px;
}

.dailyTripType > :nth-child(2) {
  font-size: 12px;
}
.bookingSectionText {
  text-align: left;
}
