* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.MobileNav_Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Circular-Std-Bold";
}

.MobileNav_Heading > div {
  font-weight: bolder;
  user-select: none;
  font-family: "Circular-Std-Bold";
}
.MobileNav_Heading > div > div {
  font-weight: normal;
  line-height: 30px;
}
.nav-rotate-arrow {
  transform: rotate(180deg);
  margin-right: 1.5%;
}
.arrow {
  margin-right: 1.5%;
}
.Hover-effect-ham {
  z-index: 30;
}
.link {
  color: black;
}
.Hover-effect-ham > .color-change:hover {
  color: #127e40;
}
.color-change {
  color: black;
  font-family: "Circular-Std-Book";
}
.Ham-subHeading {
  font-family: "Circular-Std-Bold";
  -webkit-font-family: "Circular-Std-Bold";
}
.Ham-subHeading-content {
  width: 240%;
  z-index: 1;
  user-select: none;
  font-family: "Circular-Std-Book";
}
