.myGateFormMainContainer {
  /* border: 1px solid rgba(9, 8, 26, 0.12); */
  /* border: 2px solid pink; */
  width: 100%;
  height: 100%;
  overflow: scroll;
  /* margin-bottom: 0.5rem; */
  /* height: 140px; */
}
.myGateFormAddMainContainer {
  /* border: 2px solid red; */
  width: 100%;
  height: auto;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
  color: #09081a;
}
.myGateFormAddHeadingContainer {
  font-size: 12px;
  font-weight: 700;
  color: #09081a;
}
.myGateFormAddSubMainContainer {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  background: rgba(9, 8, 26, 0.04);
  /* border-radius: 10px; */
  /* border: 2px solid pink; */
}

/* Date */
.myGateFormDateMainContainer {
  /* border: 2px solid red; */
  height: 60px;
  width: 80%;
  margin: auto;
  /* padding: 1rem; */
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.myGateFormDateContainer {
  display: flex;
  width: 32px;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(9, 8, 26, 0.04);
  padding: 0.5rem;
  /* border: 1px solid #127e40; */
}
.myGateFormDateContainer:hover {
  border: 1px solid #f93356;
}

/*  slot Time */
.myGateFormTimeSubMainContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
}

.SlotTimeMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 12px;
  background: rgba(9, 8, 26, 0.04);
  color: #09081a;
}

.SlotTimeSubMainContainer {
  padding: 0.5rem;
  border-radius: 5px;
}
.carServiceCarNumberInput {
  /* border-radius: 5px; */
  /* color: rgba(9, 8, 26, 0.4); */
  /* color: rgb(107 114 128) !important; */
  border: none;
  border: 1px solid rgba(9, 8, 26, 0.04);
  background-color: rgba(9, 8, 26, 0.04);
  font-size: 12px;
  /* border: 1px solid black; */
}

input[type="text"]:focus {
  outline-offset: 0px !important;
  --tw-ring-color: transparent !important;
  border-color: transparent;
  border: 1px solid rgba(9, 8, 26, 0.04);
  background-color: rgba(9, 8, 26, 0.04);
}

.carServiceCommentBox {
  color: #09081a;
  border: none;
  border: 1px solid rgba(9, 8, 26, 0.04);
  background-color: rgba(9, 8, 26, 0.04);
  font-size: 12px;
  resize: none;
  padding: 0.5rem;
  font-family: "Circular-Std-Book";
}

.carServiceCommentBox:focus {
  border: none;
  outline: none;
}
