.container_For_Subscription_B2B_DriverTnc{
    padding: 2rem 3rem 5rem 3rem;
    height: auto;
    overflow-y: auto;
}
/* .B2bAgreementContainer{
    padding-bottom: 2rem !important;
} */
.paraTag{
    padding: 5px;
    font-size: 15px;
}

.listStyle{
    list-style-type: none;
}
.sub_list{
    margin-left: 25px;
    list-style-type: none;
}
.sub_list2{
    margin-left: 40px;
    list-style-type: disc;
}
.headingList, .headingTopic{
    padding: 0.5rem 0;
}

.buyAndSellDriverTnc{
    border: 2px solid black;
    margin-top: 2rem;
}
.containerTermsCondition{
    padding-bottom: 6rem;
}
.customTableForCustomer{
    border-collapse: collapse;
}
@media (max-width: 1024px) {
    .containerSubscription{
        padding: 5rem !important;
    }
    .containerTermsCondition{
        padding-bottom: 5rem !important;
    }
    .headingTopic{
        font-size: 16px;
    }
    .headingList{
        padding: 0.3rem 0;
    }
    .buyAndSellDriverTnc{
        margin-top: 1rem;
    }
    .container_For_Subscription_B2B_DriverTnc {
        padding: 2rem 3rem 5rem 3rem;
    }
}

/* For mobile devices */
@media (max-width: 768px) {
    .headingTopic{
        font-size: 15px;
    }
    .headingList{
        padding: 0.2rem 0;
    }
    .buyAndSellDriverTnc{
        margin-top: 0.2rem;
    }
    .container_For_Subscription_B2B_DriverTnc {
        padding: 1rem 1.5rem; /* Adjust for mobile */
    }
    .containerTermsCondition{
        padding-bottom: 1rem !important;
    }
    .paraTag{
        font-size: 14px;
    }
}