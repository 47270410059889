.orderStatusMainDiv {
  border: 0.1px solid #97979e;
  height: auto;
  width: 60%;
  padding-bottom: 1rem;
  border-radius: 5px;
}
.statusHeading {
  width: 100%;
  background-color: #eaeaea;
  height: 43px;
  color: #333;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 0.5rem;
  border-radius: 5px;
  font-family: "Circular-Std-Medium";
}
.viewDetalisDiv {
  font-family: "Circular-Std-Book";
}
.orderDiv,
.pastOrderDiv {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
}
.activeOrder,
.pastOrder {
  height: auto;
  border: 0.2px solid #97979e;
  width: 90%;
  padding: 0.5rem;
  margin-left: 2rem;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.activeOrderHeading {
  font-size: 21px;
  font-weight: bold;
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  font-family: "Circular-Std-Book";
  /* border: 1px solid red; */
}
.activeOrderDiv,
.pastOrderDiv {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: space-between;
}
.combineDiv,
.combinePastDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 60%;
}
.activeOrderImage,
.pastOrderImage {
  height: 60px;
  width: 60px;
}
.activeOrderImage > img,
.pastOrderImage > img {
  height: 100%;
  width: 100%;
}
.activeOrderduration,
.pastOrderduration {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 0.3rem;
  color: #97979e;
  font-family: "Circular-Std-Book";
}

.statusButton > div,
.paststatusButton > div {
  margin-top: 1rem;
  padding: 0.3rem;
  border-radius: 5px;
  color: white;
  background-color: green;
  font-family: "Circular-Std-Book";
}
.statusAddress,
.paststatusAddress {
  font-size: 14px;
  margin-left: 1.5rem;
  margin-top: 1rem;
  padding: 0.4rem;
  border-radius: 5px;
  color: #97979e;
  font-family: "Circular-Std-Book";
}
.statusAddress {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: 0.5rem;
  border-bottom: 1.2px dotted #97979e;
  border-radius: 0px;
}
.statusAddress > div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.trackAndCancelButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 25px;
  padding: 0.5rem;
}
.trackAndCancelButtonDiv {
  min-height: 35px;
  width: 48%;
  background-color: black;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  margin-top: 0.5rem;
  font-size: 14px;
}
.paststatusAddress {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  border-radius: 0%;
  border-bottom: 1.2px dotted #97979e;
  /* border: 1px solid red; */
}
.modifyBookingPopUp {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modifyBookingPopUp div {
  min-height: 35px;
  width: 45%;
  /* background-color: black; */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  border: 2px solid gray;
}
.reschedulePopUp {
  min-height: 35px;
  width: 45%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Circular-Std-Book";
  border: 2px solid black !important;
}

.paststatusAddress > div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem;
}

.LocationDots {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 20px !important;
  left: 40%;
  height: 60px;
  margin-top: 0px;
}
.LocationDotsSvg {
  width: 10px;
}
.LocationLine {
  border-left: 2px dotted #666;
  height: 20px;
  margin-left: 4px;
}
.NoActiveImage {
  border-bottom: 2px dotted #888;
  width: 95%;
  margin: auto;
  height: 200px;
}
.NoActiveImage > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.BookNowActiveOrderDiv {
  width: 95%;
  background-color: black;
  color: white;
  margin: auto;
  margin-top: 1rem;
  /* text-align: center; */
  min-height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  font-family: "Circular-Std-Book";
}
@media (max-width: 550px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 100%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .LocationDots {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20px !important;
    left: 18%;
    height: 60px;
    margin-top: 0px;
  }
  .LocationDotsSvg {
    width: 10px;
  }
  .LocationLine {
    border-left: 2px dotted #666;
    height: 20px;
    margin-left: 4px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 68%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
}
@media (min-width: 770px) and (max-width: 920px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 68%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .LocationDots {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20px !important;
    left: 41%;
    height: 60px;
    margin-top: 0px;
  }
  .LocationDotsSvg {
    width: 10px;
  }
  .LocationLine {
    border-left: 2px dotted #666;
    height: 20px;
    margin-left: 4px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 68%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
}
@media (min-width: 550px) and (max-width: 769px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 100%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .LocationDots {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20px !important;
    left: 16%;
    height: 60px;
    margin-top: 0px;
  }
}

@media (max-width: 550px) {
  .orderStatusMainDiv {
    border: 0.1px solid #97979e;
    height: auto;
    width: 100%;
    padding-bottom: 1rem;
    border-radius: 5px;
  }
  .activeOrder,
  .pastOrder {
    height: auto;
    border: 0.2px solid #97979e;
    width: 90%;
    padding: 0.5rem;
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .LocationDots {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 20px !important;
    left: 18%;
    height: 60px;
    margin-top: -3px;
  }
  .statusHeading {
    font-size: 21px;
  }
  .activeOrderHeading {
    font-size: 18px;
  }
  .activeOrderImage {
    width: 60px;
    height: 60px;
  }
  .activeOrderImage > img,
  .pastOrderImage > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .activeOrderduration,
  .pastOrderduration {
    font-size: 12px;
    width: 100%;
  }
  .statusButton > div,
  .paststatusButton > div {
    font-size: 12px;
  }
  .statusAddress {
    font-size: 12px;
  }
  .paststatusAddress {
    font-size: 12px;
  }
  .viewDetalisDiv {
    font-size: 12px;
  }
}
