.successLoader {
  /* border: 1px solid red; */
  height: 200px;
  width: 200px;
  margin: auto;
  margin-top: 1rem;
}
.successOrderPlaced {
  align-items: center;
  /* border: 1px solid teal; */
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
.successOrderPlacedImageContainer {
  /* border: 1px solid red; */
  height: 100%;
  width: 7%;
}
.successOrderPlacedImageContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.successOrderPlacedHeading {
  font-size: 16px;
  font-family: "Circular-Std-Bold";
}
.successTextContainer {
  /* border: 1px solid teal; */
  height: 30%;
  width: 95%;
  margin: auto;
  text-align: center;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
}

.successTrackMainContainer {
  /* background-color: black; */
  height: 50px;
  border-radius: 10px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #f93356;
}

.carServiceSuccesLoader {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border: 1px solid #f93356;
}
