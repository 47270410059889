.successWrapper {
  /* border: 2px solid red; */
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.successText {
  width: 50%;
  /* border: 2px solid red; */
}
