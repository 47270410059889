.fontMedium {
  font-family: "Circular-Std-Medium" !important;
  color: #666;
}
.oneWayImgDiv {
  width: 100%;
  height: 250px;
  background-image: url("https://www.driveu.in/static/web/v3/images/oneWay.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.inputBox {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 96%;
  margin-left: 0.5rem;
}
.oneWayInputBox {
  margin-top: 0.5rem;
}
.oneWayBottomDiv {
  padding-left: 1rem;
}
.DataAndTimeInput {
  display: flex;
  flex-direction: row;
}
.Date {
  width: 55%;
  display: flex;
  flex-direction: row;
}
.Date > div {
  width: 33.5%;
}
.dateInput {
  height: 43px;
  width: 110%;
  border: none;
  border-bottom: 0.2px solid #888;
  color: #666;
  padding-left: 8%;
  font-family: "Circular-Std-Book";
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.dateInput:focus {
  outline: none !important;
}

.dateInput::placeholder {
  margin-left: 2px !important;
}
.Date img,
.Time img {
  margin-top: 1.5rem;
}
.Time {
  width: 40%;
  display: flex;
  flex-direction: row;
}
.Time > div {
  cursor: pointer;
}
.dateOption {
  background-color: white;
  width: 15%;
  padding: 0.5rem;
  border-radius: 2px;
  border: 0.2px solid #888;
  margin-right: 0.5rem;
  margin-bottom: 5px;
  cursor: pointer;
  color: #666;
  font-family: "Circular-Std-Book";
  font-size: 16px;
}
.dateDiv {
  display: flex;
  width: 95%;
  flex-direction: row;
  background-color: white;
  overflow-x: scroll;
}

.dateOption:hover {
  border: 2px solid #127e40;
  border-radius: 5px;
}

.carDetailOption {
  border: none !important;
  border-bottom: 0.2px solid #888 !important;
  font-family: "Circular-Std-Book";
}

.CarTypeBlock {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-family: "Circular-Std-Book";
  width: 96%;
}
.InsuranceBlock {
  font-family: "Circular-Std-Book";
}
.carDetailOption:focus {
  outline: none;
}

.coupanMainDiv {
  margin-bottom: 0.5rem;
}
.Coupon > button {
  height: 35px;
  width: 25%;
  background-color: white;
  color: #666;
  margin-left: 5px;
  font-size: 14px;
  border: 0.2px solid #127e40;
  border-radius: 5px;
  font-family: "Circular-Std-Book";
}
.Coupon > button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1.5px solid #127e40;
}
.err-msg-text {
  color: red;
  text-align: center;
}
.OneWayDateAndTime {
  color: #888;
  font-family: "Circular-Std-Book";
  font-size: 16px;
}
.RequestDriver {
  font-family: "Circular-Std-Medium";
  color: #ffffff;
}
table,
th,
td {
  border: 1px solid #888;
  border-collapse: collapse;
  padding: 0.5rem;
  border-color: #888;
}

.Time input {
  caret-color: transparent;
  color: #666 !important;
}
.Time div {
  background-color: white !important;
  color: #666 !important;
  cursor: pointer;
}

.Time .fx,
.Time .be {
  border: none !important;
  border-bottom: 0.2px solid #888 !important;
}
.CarType {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 20px;
}
.carTypeDropDown,
.carModelDropDown {
  font-family: "Circular-Std-Book";
  border: none;
  padding: 1%;
  outline: none;
}
.newPromoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}
.newPromoDiscountImage {
  font-size: 28px;
  font-weight: bolder;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid white;
  background-color: #127e40;
  padding: 4px;
}
.promoCodeBottomSheet {
  text-align: center;
  color: #09081a;
  font-size: 16px;
  font-weight: 700;
}
.promoBottomSheetText {
  color: #09081a;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}

.stickyPromoCode {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  border-bottom: 2px solid #e7e7e7;
  padding: 0rem 0rem 0.75rem 0rem;
}
.firstBlockCouponWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7%;
}
.firstBlockCouponWrapperPopUp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3%;
}
/* .firstBlockCouponWrapperPopUp.disabled {
  pointer-events: none;
} */
.couponCodeBottomSheet {
  background-color: #06d585;
  color: black;
  font-weight: 700;
  width: 100px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.firstF1 {
  color: #137e40;
  font-weight: 700;
}
.firstF1PopUp {
  border: none;
  color: #137e40;
  font-weight: 700;
  background-color: #ffffff;
}
.secondBlockCouponWrapper {
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
}
.secondBlockCouponWrapperPopUp {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
}
.bottomS1 {
  width: 80px;
  height: 20px;
}
.bottomS2 {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
}
.bottomS3 {
  color: #09081a;
  opacity: 0.7;
  font-size: 14px;
}
.thirdBlockCouponWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  margin-top: 7%;
}
.thirdBlockCouponWrapperPopUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  margin-top: 1%;
  cursor: pointer;
}
.thirdT1 {
  color: gray;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
}

.fourthBlockCouponWrapper {
  margin-top: 7%;
  color: red;
  font-size: 16px;
}
.fourthBlockCouponWrapperPopUp {
  margin-top: 2%;
  color: red;
  font-size: 14px;
}
.bottomCouponContainer {
  width: 100%;
  height: auto;
}
.bottomCouponWrapper {
  width: 100%;
  height: auto;
}
.fiveT1 {
  margin-top: 7%;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    #000,
    #000 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 1px;
  border: none;
}
.fiveT1PopUp {
  margin-top: 2%;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    #000,
    #000 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 1px;
  border: none;
}
.inputCouponCode {
  width: 100%;
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Coupon {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.couponCodeBottomSheetInput {
  width: 100%;
  margin-right: 6%;
  font-family: "Circular-Std-Book";
  border: none;
}
.couponCodeBottomSheetInput::placeholder {
  color: #61616c;
  opacity: 0.6;
  font-size: 14px;
}
.inputCouponCode > button {
  height: 35px;
  width: 25%;
  background-color: white;
  color: #666;
  margin-left: 5px;
  font-size: 14px;
  border: none;
  font-family: "Circular-Std-Book";
}
.couponDetails {
  border: none !important;
  font-family: "Circular-Std-Book";
}
#couponDiv,
#couponBtn {
  background-color: #f3f4f6;
}
.couponText {
  width: 84%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.couponText span {
  color: #191c19;
}
.actionOffer {
  font-family: "Circular-Std-Book";
  font-size: 12px;
  color: #191c19;
  opacity: 0.7;
}
.shimmer {
  position: relative;
  overflow: hidden;
}

.shimmer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #f6f7f8 50%,
    transparent 100%
  );
  animation: shimmerAnimation 2s infinite;
}

@keyframes shimmerAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.successPromoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.successPromoF1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.PromoF1 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.PromoF2 {
  font-family: "Circular-Std-Book";
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #61616c;
}
.PromoF12 {
  font-family: "Circular-Std-Book";
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0px;
}
.PromoF11 {
  color: #009d61;
  font-size: 16px;
}
.otherOffersO1 {
  text-align: center;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #009d61;
  font-family: "Circular-Std-Book";
}
.newPromoSuccessContainer {
  border: 2px solid #e6e6e8;
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  animation: offerAnim 2s ease 0s 1 normal;
}
@keyframes offerAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.successPromoF2 {
  font-family: "Circular-Std-Book";
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0px;
  color: #009d61;
}

.newYearMessage {
  /* border: 2px solid red; */
  width: 90%;
  margin: auto;
  margin-bottom: 2rem;
  color: red;
  font-size: 14px;
}
