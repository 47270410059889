.viewDetails {
  width: 40%;
  height: fit-content;
  /* height: auto; */
  /* max-height: 62%; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  opacity: 1;
  z-index: 2000;
  background: #ffffff;
  box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
  border-radius: 25px !important;
}
.viewDetails {
  border: 0.2px solid #97979e;
  padding: 0.5rem;
  margin-left: 2rem;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.viewDetails {
  margin-top: 1rem;
}
.viewDetailsDiv {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: space-between;
  padding: 0.5rem;
}
.combineViewDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 60%;
}
.detailsImage {
  height: 60px;
  width: 60px;
}
.detailsImage > img {
  height: 100%;
  width: 100%;
}
.detailsduration {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #97979e;
  font-family: "Circular-Std-Book";
}
.detailsButton > div {
  /* border: 0.2px solid green; */
  margin-top: 1rem;
  padding: 0.3rem;
  border-radius: 5px;
  color: white;
  background-color: green;
  font-family: "Circular-Std-Book";
}
.detailsAddress {
  font-size: 14px;
  margin-left: 2rem;
  margin-top: 1rem;
  padding: 0.4rem;
  border-radius: 5px;
  color: #97979e;
  font-family: "Circular-Std-Book";
  /* border: 2px solid red; */
}
.detailsAddress {
  font-size: 14px;
  margin-left: 0.5rem;
  margin-top: 1rem;
  padding: 0.4rem;
  border-radius: 5px;
  color: #97979e;
}
.detailsAddress > .detailsDateAndDuration {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.4rem;
}
.detailsDateAndDuration {
  line-height: 1.5;
}
.detailsAddress .viewAddress,
.detailsAddress .viewAddressHeading {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 0.4rem; */
}
.viewDetailsTitleAndAddress {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.viewDetalisDiv {
  width: 100%;
  background-color: black;
  color: white;
  margin-top: 1rem;
  /* text-align: center; */
  min-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.fareANDpayment {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.viewDetailsFare {
  font-family: "Circular-Std-Book";
  /* padding-left: 1rem; */
  border-top: 2px dotted gray;
  border-bottom: 2px dotted gray;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* border: 1px solid green; */
}
.onlinePaymentButton {
  width: 100%;
  font-family: "Circular-Std-Bold";
  color: #ffffff;
  border: none;
  background: #000000;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  cursor: pointer;
  padding-left: 1rem;
  margin-top: 2rem;
}
.viewDetailsFare > div {
  padding-top: 0.5rem;
}
.closeDriverDetails {
  font-size: 37px;
  color: white;
  background: #000000;
  border-radius: 50px;
  float: right;
  margin-top: -20px;
  margin-right: -20px;
}
.LocationDotsPopUp {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 20px !important;
  left: 2%;
  /* top: 32.5%; */
  margin-top: -8px;
  height: 100px;
}
.LocationDotsSvgPopUp {
  width: 10px;
}
.LocationLinePopUp {
  border-left: 2px dotted #666;
  height: 25px;
  margin-left: 4px;
}
.driverRating > div {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}

@media (max-width: 767px) {
  .viewDetails {
    width: 80%;
    /* height: 48%; */
    /* height: auto; */
    /* max-height: 50%; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 25px;
  }
  .detailsImage > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .detailsduration {
    font-size: 12px;
  }
  .detailsButton > div {
    font-size: 12px;
  }
  .detailsAddress div {
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .viewDetails {
    width: 50%;
    /* height: 52%; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 25px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .viewDetails {
    width: 40%;
    /* height: 52%; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto !important;
    opacity: 1;
    z-index: 2000;
    background: #ffffff;
    box-shadow: 3.80914px 3.80914px 49.0041px rgba(0, 5, 46, 0.08);
    border-radius: 25px;
  }
}
