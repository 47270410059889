.orderProfileMainDiv {
    /* border: 1px solid red; */
    height: auto;
    width: 30%;
    box-shadow: inset 0 0 10px #ccc;
  }
  .profileInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    gap: 0.5rem;
    border-bottom: 3px solid green;
  }
  .profileInfo div {
    font-family: "Circular-Std-Book";
    font-size: 16px;
    color: #97979e;
  }
  .profileInfoImg {
    height: 70px;
    width: 70px;
  }
  .profileInfoImg > img {
    height: 100%;
    width: 100%;
  }
  .infoOption {
    height: 43px;
    border: 0.1px solid #97979e;
    padding: 0.5rem;
    font-family: "Circular-Std-Book";
    font-size: 17px;
    color: #97979e;
    text-decoration: none;
  }
  @media (max-width: 769px) {
    .orderProfileMainDiv {
      display: none;
    }
}  