.signupdetails_popup {
  width: 45%;
  height: 685px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  opacity: 1;
  background-color: white;
  z-index: 2000;
  border-radius: 22.8548px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.content-phone-signup {
  /* border:5px solid red; */
  width: 85%;
  margin: 60px auto;
}
.top-content-signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popupdetails {
  width: 85%;
  margin: 30px auto;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.user_details_block {
  /* border:2px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.head-signup {
  text-align: center;
  font-family: "Circular-Std-Bold";
}
h3 {
  text-align: center;
}
.user_details_block > input[type="text"] {
  width: 100%;
  height: 43px;
  /* border: 1px solid #ccc; */
  color: #333;
  font-size: 16px;
  transition: all 0.5s ease-in;
  outline: none;
  padding: 10px;
}
.user_details_block > input[type="email"] {
  width: 100%;
  border: none;
  border: 1px solid #ccc;
  padding: 10px;
  color: #333;
  font-size: 16px;
  transition: all 0.5s ease-in;
  outline: none;
}
.signup {
  background-color: #000000;
  text-align: center;
  color: #fff;
  font-size: 16px;
  width: 100%;
  height: 43px;
  padding: 8px;
  background-repeat: no-repeat;
  border: none;
  font-family: "Circular-Std-Medium";
}
.signup:hover {
  opacity: 0.9;
}
.user_details_block > input[type="text"]:focus {
  border: 2px solid black;
  background-color: #ffffff;
}
/* .user_details_block > input::-webkit-outer-spin-button,
.user_details_block > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.user_details_block > input[type="text"] {
  -moz-appearance: textfield;
} */

.closedetails {
  width: 100%;
  /* border:2px solid red; */
}
.tnc {
  color: #09081a;
  font-size: 16px;
  text-decoration: underline;
  /* font-weight: 500; */
  cursor: pointer;
  font-family: "Circular-Std-Book";
}
.close_Btn {
  font-size: 45px;
  color: white;
  background: #000000;
  border-radius: 50px;
  float: right;
  margin-top: -15px;
  margin-right: -15px;
}
.otpimg2 {
  width: 20%;
}
.check_term {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}
span > input[type="checkbox"] {
  width: 24px;
  height: 24px;
}
.err_msg_signup {
  text-transform: uppercase;
  color: rgba(193, 0, 0, 0.7);
  font-family: "Circular-Std-Book";
  width: 280px;
  text-align: center;
  font-size: 14px;
  width: 100%;
  margin-top: 0.5rem;
}
.terms_services {
  font-family: "Circular-Std-Book";
  color: #09081a;
}
@media (min-width: 1023px) and (max-width: 1279px) {
  .signupdetails_popup {
    width: 45%;
    height: 555px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    z-index: 2000;
    /* border:2px solid red; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .content-phone-signup {
    margin: auto;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .signupdetails_popup {
    width: 50%;
    height: 530px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    opacity: 1;
    background-color: white;
    z-index: 2000;
    /* border:2px solid red; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .content-phone-signup {
    margin: auto;
  }
}
@media (max-width: 540px) {
  .signupdetails_popup {
    width: 90%;
    height: 450px;
    /* border: 1px solid red; */
  }
  .content-phone-signup {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    /* border: 1px solid red; */
  }
  .top-content-signup {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0rem;
  }
  .otpimg2 {
    min-width: 80px;
  }
  .popupdetails {
    row-gap: 0.5rem;
    width: 90%;
  }
  .user_details_block {
    gap: 1rem;
  }
  .err_msg_signup {
    font-size: 10px;
  }
  .close_Btn {
    font-size: 30px;
    color: white;
    background: #000000;
    border-radius: 50px;
    float: right;
    margin-top: -10px;
  }
  .tnc,
  .terms_services {
    font-size: 13.5px;
  }
  .err_msg_signup {
    font-size: 10px;
  }
}
@media (min-width: 541px) and (max-width: 767px) {
  .signupdetails_popup {
    width: 60%;
    height: 440px;
    /* border: 1px solid red; */
  }
  .content-phone-signup {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    /* border: 1px solid red; */
  }
  .top-content-signup {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0rem;
  }
  .otpimg2 {
    min-width: 80px;
  }
  .popupdetails {
    row-gap: 0.5rem;
    width: 90%;
  }
  .user_details_block {
    gap: 1rem;
  }
  .err_msg_signup {
    font-size: 10px;
  }
  .close_Btn {
    font-size: 30px;
    color: white;
    background: #000000;
    border-radius: 50px;
    float: right;
    margin-top: -10px;
  }
  .tnc,
  .terms_services {
    font-size: 13.5px;
  }
  .err_msg_signup {
    font-size: 10px;
  }
}
