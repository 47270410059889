.mainDiv {
  background-image: url("https://ik.imagekit.io/sv85rtoxx/Map-Animation.gif");
  width: 100%;
  height: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-size: 100% 100%; */
  background-position: top;
}
.secondMainDiv {
  height: auto;
  width: 100%;
}
.smallDiv {
  position: relative;
  float: left;
  width: 360px;
  margin-left: 5rem;
  margin-top: 3%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  height: auto;
}
.oneWay,
.roundWay {
  height: 58%;
}
.inputBox {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 96%;
}
.suggestion {
  cursor: pointer;
}
.smallMyGateDiv {
  max-width: 350px;
  width: 100%;
  float: none;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  height: auto;
  margin-top: 10%;
}
.MyGateTextHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: white;
}
@media (max-width: 767px) {
  .mainDiv {
    background-image: url("");
    height: auto;
  }
  .smallDiv {
    max-width: 340px;
    width: 100%;
    float: none;
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    height: auto;
  }
}
@media (min-width: 767px) and (max-width: 1023px) {
  .mainDiv {
    height: 800px;
    background-size: 100% 100%;
  }
}
