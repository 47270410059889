.carServiceMainContainer {
  border: 1px solid #e6e6e8;
  width: 26%;
  /* height: 700px; */
  /* height: 100vh; */
  margin: auto;
  position: fixed;
}
.carServiceHeadingMainContainer {
  height: 10%;
  width: 100%;
  background-color: #25252e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.carServiceLeftHeadingMainContainer {
  /* border: 2px solid green; */
  height: 60%;
  width: 35%;
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  justify-content: space-between;
}
.carServiceBackButtonMainContainer {
  /* border: 2px solid teal; */
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}

.carServiceBackButton {
  color: #fafafa;
  font-size: 20px;
}
.carServiceHeadingTextMainContainer {
  /* border: 2px solid red; */
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fafafa;
  font-size: 20px;
  font-family: "Circular-Std-Book";
  /* font-style: normal; */
}
.carServiceRightHeadingMainContainer {
  border: 1px solid #e6e6e8;
  border-radius: 5px;
  /* width: 40%; */
  width: auto;
  height: 55%;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.carServiceCarMainContainer,
.carServiceHeadingDownMainContainer {
  height: 100%;
  display: flex;
  align-items: center;
  color: #fafafa;
}
.carServiceCarMainContainer {
  margin-left: 0.5rem;
}
.carServiceHeadingDownMainContainer {
  margin-right: 0.5rem;
}
.carServiceRightHeading {
  display: flex;
  align-items: center;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  color: #fafafa;
  font-size: 10px;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.carServiceCarOptionMainContainer {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* justify-content: end; */
  justify-content: flex-end;
  margin-right: 28%;
}
.carServiceCarOption {
  margin-right: 1rem;
  /* border: 2px solid green; */
}

.carServiceTabOptionMainContainer {
  /* border: 2px solid red; */
  /* border-bottom: 0.5px solid #9b9b9b; */
  width: 95%;
  height: 10%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.generalServiceMainContainer {
  padding-top: 0.5rem;
  /* border: 2px solid red; */
  width: 23%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
  text-align: center;
  padding-bottom: 0.5rem;
}

.generalServiceMainContainer > img {
  height: 60%;
  width: 70%;
  object-fit: contain;
}
.generalServiceTextContainer {
  color: #9b9b9b;
  font-size: 10px;
  line-height: 130%;
  font-family: "Circular-Std-Book";
  font-weight: 700;
}
.carServiceBottomMainContainer {
  /* border: 2px solid teal; */
  width: 100%;
  height: 80%;
  padding-bottom: 1rem;
  overflow: scroll;
}
.carServiceVideoMainContainer {
  width: 100%;
  margin: auto;
  height: auto;
  position: static;
  /* border: 2px solid red; */
}
.carServiceVideoMainContainer > img {
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
  /* object-fit: contain; */
}

.carServiceOfferMainContainer {
  width: 90%;
  height: 14%;
  margin: auto;
  margin-top: 1rem;
  border: 1.5px solid #e6e6e8;
  border-radius: 10px;
  background: #ffffff;
}

.carServiceOfferLeftMainContainer {
  /* border: 1px solid red; */
  width: 10%;
  height: 100%;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carServiceOfferLeftMainContainer > img {
  height: 60%;
  width: 80%;
  object-fit: contain;
}
.carSerivceOfferCentreMainContaier {
  /* border: 1px solid teal; */
  height: 100%;
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  row-gap: 10%;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.carSerivceOfferCentreMainHeading {
  color: #09081a;
  line-height: 120%;
  font-weight: 700;
  font-family: "Circular-Std-Book";
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.carSerivceOfferCentreSubMainHeading {
  color: #09081a;
  font-size: 12px;
  font-family: "Circular-Std-Book";
}

.carServiceRightMainContainer {
  width: 20%;
  height: 100%;
  margin-right: 0.5rem;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5%;
}
.carServiceOfferMapContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.carServiceCoupanCountsMainContainer {
  height: 100%;
  color: #009d61;
  /* margin-bottom: 0.5rem; */
}
.coupanDotsMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5%;
  justify-content: center;
}
.coupanDotsContainer {
  color: #9b9b9b;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* car Service Options start */
.carServiceAllOptionMainContainer {
  /* border: 2px solid red; */
  border: 1.5px solid #e6e6e8;
  width: 90%;
  margin: auto;
  height: 50%;
  margin-top: 1.5rem;
  border-radius: 10px;
}
.carServiceAllOptionTopMainContainer {
  border-bottom: 1.5px solid #e6e6e8;
  width: 95%;
  height: 40%;
  margin: auto;
  display: flex;
  align-items: center;
}
.carServiceAllOptionTopContainer {
  /* border: 1px solid green; */
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carServiceAllOptionTopLeftContainer {
  /* border: 1px solid teal; */
  /* justify-content: space-between; */
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  line-height: 150%;
}
.carServiceAllOptionTopLeftHeading {
  font-family: "Circular-Std-Book";
  font-weight: 700;
  font-size: 16px;
  color: #09081a;
  /* border: 1px solid red; */
}
.carServiceAllOptionTopLeftRating {
  font-family: "Circular-Std-Book";
  font-weight: 450;
  font-size: 14px;
  color: #aaaca7;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
}
.carServiceAllOptionImg {
  /* border: 1px solid red; */
  height: 60%;
  width: 10%;
}
.carServiceAllOptionImg > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.carServiceAllOptionTopLeftPrice {
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 14px;
  color: #09081a;
}
.carServiceAllOptionTopLeftETATime {
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 12px;
  color: #aaaca7;
}

/*  right container */
.carServiceAllOptionTopRightContainer {
  /* border: 1px solid black; */
  height: 80%;
  width: 40%;
}
.carServiceAllOptionTopRightContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* bottom main Container */
.carServiceAllOptionBottomMainContainer {
  /* border: 1px solid red; */
  width: 90%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  margin-top: 0.5rem;
}

.carServiceAllOptionBottomOptionContainer {
  /* border: 1px solid red; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carServiceAllOptionBottomFirstOptions {
  width: 100%;
  height: 20%;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #8f918d;
  /* border: 1px solid red; */
}

.carServiceViewDetailsMainContainer {
  width: 100%;
  margin: auto;
  height: 25%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  justify-content: space-between;
}
.carServiceViewDetailsContaier {
  color: #009d61;
  font-family: "Circular-Std-Book";
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  display: flex;
  align-items: center;
}
.carServiceBookNowContaier {
  font-family: "Circular-Std-Book";
  font-weight: 700;
  font-size: 12px;
  color: #009d61;
  width: 40%;
  border: 1.5px solid #e6e6e8;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
}
/* car Service Options end */

/* slide show image start */

#imageSlide {
  background-color: black;
  height: 100%;
  width: 100%;
}
#carServiceSlideDots {
  width: 40%;
  height: 10%;
  margin: auto;
  margin-top: -2rem;
  text-align: center;
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.dotMainContainer {
  color: white;
  font-weight: 700;
}
.bottomDot {
  height: 20px;
  width: 20px;
}

/*  react silk slide */
.slick-dots li.slick-active button:before {
  color: green; /* Active dot color */
}
.slick-dots li {
  margin: 0px;
}

/* slide show image end */

/*  empty service arr */
.carServiceEmptyMainContainer {
  /* border: 1px solid red; */
  width: 90%;
  height: 50%;
  margin: auto;
  margin-top: 1rem;
}
.carServiceEmptyLottie {
  /* border: 1px solid teal; */
  height: 80%;
  width: 90%;
  margin: auto;
  object-fit: contain;
}
.carServiceEmptyMainHeading {
  text-align: center;
  color: #9b9b9b;
  font-size: 14px;
  margin-top: -2rem;
}
@media (max-width: 767px) {
  .carServiceMainContainer {
    width: 100%;
    position: fixed;
  }
}
