.carServiceFuelMainContainer {
  /* border: 1px solid red; */
  width: 100%;
  height: 10%;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.carServiceFuelMainContainer div {
  width: 50%;
  height: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}
