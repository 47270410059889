.main_Div {
  height: auto;
  width: 100%;
  max-width: 120em;
  display: flex;
  flex-direction: column;
  gap: 4%;
  font-family: sans-serif;
  background-color: rgb(9, 8, 26);
  color: white;
  padding: 4%;
  margin: auto;
}
a {
  text-decoration: none;
}

.first_div {
  width: 95%;
  height: 60%;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 3%;
}

.second_div {
  width: 95%;
  height: 35%;
  margin: auto;
  margin-top: 5%;
}

.first_sec,
.first_fir,
.first_thi,
.first_fou,
.first_fif,
.first_six {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.first_thi_sec,
.first_sec_sec,
.first_fou_sec,
.first_six_sec,
.first_fif_sec {
  margin-top: 5%;
}

.size {
  font-size: large;
  padding: 3px;
  font-family: "Circular-Std-Book";
}

.size > a {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Circular-Std-Book";
}
.first_fir_first,
.first_thi_first,
.first_sec_first,
.first_fou_first,
.first_six_first,
.first_fif_first {
  margin-top: 20%;
  font-weight: bold;
  color: #ffffff;
  font-family: "Circular-Std-Bold";
}
.follow {
  margin-top: 25%;
  font-size: large;
  font-family: "Circular-Std-Bold";
}
.App_Image {
  display: flex;
  flex-direction: col;
  gap: 5%;
  margin-top: 5%;
}

.image_height_width > a {
  height: 80%;
  width: 14%;
  border-radius: 50%;
}

.first_fir_first {
  height: 15%;
  width: 70%;
}
.second_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.second_first {
  width: 45%;
  height: 60%;
  display: flex;
  flex-direction: column;
  gap: 6%;
}

.second_sec {
  width: 35%;
  height: 50%;
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.footer_text {
  font-size: 12px;
  opacity: 70%;
  font-family: "Circular-Std-Book";
}

.app_image_div > a {
  height: 70%;
  width: 48%;
}
.app_image_div img,
.first_fir_first > img {
  height: 100%;
  width: 100%;
}
.image_height_width img {
  height: 90%;
  width: 100%;
}
.term {
  font-weight: bolder;
  font-family: "Circular-Std-Bold";
  opacity: 0.8;
}
.term a {
  color: white;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .first_div {
    flex-direction: column;
    text-align: center;
  }
  .footer-logo {
    margin-top: 3rem;
  }
  .main_Div {
    width: auto;
  }
  .first_fir_first,
  .first_thi_first,
  .first_sec_first,
  .first_fou_first,
  .first_six_first,
  .first_fif_first {
    font-size: 25px;
    margin-top: 10%;
    font-weight: bold;
  }

  .first_sec,
  .first_fir,
  .first_thi,
  .first_fou,
  .first_fif,
  .first_six {
    width: 90%;
    margin: auto;
  }

  .size {
    padding: 5px;
    font-size: 15px;
  }
  .first_fir_first {
    margin: auto;
  }
  .follow {
    margin-top: 10%;
  }
  .App_Image {
    justify-content: center;
  }

  .second_div {
    flex-direction: column;
  }
  .second_first {
    width: 95%;
    padding: 5%;
    text-align: center;
  }
  .second_first > div {
    font-size: small;
    padding: 1.5%;
  }
  .second_sec {
    padding: 5%;
    width: 65%;
    height: 30%;
  }
  .first_fir {
    order: 1;
  }
  .image_height_width {
    height: 40%;
    width: 8%;
  }

  .first_fir_first {
    width: 30%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .first_fir_first,
  .first_thi_first,
  .first_sec_first,
  .first_fou_first,
  .first_six_first,
  .first_fif_first {
    font-size: 15px;
    margin-top: 20%;
    font-weight: bold;
  }

  .size {
    font-size: 8px;
  }

  .footer_text {
    font-size: 8px;
  }
  .follow {
    font-size: medium;
  }
  .app_image_div {
    height: 50%;
    width: 70%;
  }
  .first_fir_first {
    height: 10%;
    width: 75%;
  }
  .second_first > div {
    padding: 0.75%;
  }
  .term {
    font-size: 12px;
  }
  .main_Div {
    width: auto;
  }
}

@media only screen and (min-width: 1025px) {
  .first_fir_first,
  .first_thi_first,
  .first_sec_first,
  .first_fou_first,
  .first_six_first,
  .first_fif_first {
    font-size: 22px;
  }
  .size {
    font-size: 12px;
  }

  .footer_text {
    font-size: 10px;
  }
  .second_first > div {
    padding: 0.75%;
  }
}
