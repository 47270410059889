.shareCredentialsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1rem;
}
.myGateAllowTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.myGateAllowBtn {
  width: 100%;
}
