:root {
  --font-Circular-Bold: "Circular-Std-Bold";
  --font-Circular-Medium: "Circular-Std-Medium";
  --font-Circular-Book: "Circular-Std-Book";
}
.aboutus-wrapper {
  max-width: 198px;
  max-height: 144px;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  background: #ffffff;
  position: absolute;
  top: 100%;
  background: #ffffff;
  border: 0.5px solid rgba(9, 8, 26, 0.12);
  box-shadow: 4px 4px 44px rgba(0, 5, 46, 0.08);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-us-section {
  height: 100%;
  width: 100%;
  list-style: none;
  padding-left: 0px;
}
.about-us-section li {
  height: 33.3%;
  width: 100%;
  list-style: none;
  box-sizing: border-box;
  padding: 15px 16px;
  font-family: var(--font-Circular-Book);
  font-weight: 450;
  line-height: 96%;
}
.about-us-section > a {
  color: black;
}
.about-us-section li:hover {
  background: #09081a;
  color: #fcfcfd;
  border-radius: 10px;
}
.about-us-option {
  border-radius: 0px;
}
.aboutus-story:hover {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.aboutus-contact:hover {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
