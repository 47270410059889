.fuelPriceMainContainer {
  width: 40%;
  height: 650px;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.fuelPriceBackButton {
  height: 80%;
  width: 5%;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  background: linear-gradient(
    rgb(239, 233, 251) 0%,
    rgba(239, 233, 251, 0) 100%
  );
  color: #f93356;
}
.fuelPriceSubMainContainer {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.fuelPriceContainer {
  background: linear-gradient(
    rgb(239, 233, 251) 0%,
    rgba(239, 233, 251, 0) 100%
  );
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}
.fuelPriceHeadingContainer {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.fuelPriceHeading {
  color: black;
  font-family: "Circular-Std-Book";
  font-size: 18px;
}
.fuelPriceSubHeading {
  color: rgb(136, 136, 136);
  font-family: "Circular-Std-Book";
  font-size: 14px;
}
.fuelPriceInputMainContainer {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.fuelPriceInputContainer {
  border: 1px solid black;
  width: 96%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40%;
}
.fuelPriceInputContainerText {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: "Circular-Std-Book";
  color: black;
  width: 70%;
  height: 100%;
  margin-left: 1rem;
}
.fuelPriceInputContainerArrow {
  display: flex;
  align-items: center;
  height: 100%;
  width: 5%;
  margin-right: 0.5rem;
}

.cityWiseFuelMainDivContainer {
  padding-top: 1rem;
  width: 100%;
  padding-left: 0.2rem;
  height: 75%;
  display: flex;
  flex-direction: column;
  gap: 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cityWiseFuelMainDivContainerHeading {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  color: black;
  margin-left: 0.5rem;
  font-family: "Circular-Std-Book";
  font-size: 18px;
}
.cityWiseFuelMainDivContainerTabel {
  width: 100%;
  height: 60%;
}
.cityWiseFuelFirstTabelOption {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cityWiseFuelFirstTabelOption > div {
  width: 25%;
  text-align: end;
  color: black;
  font-family: "Circular-Std-Book";
  font-size: 16px;
  margin-right: 0.5rem;
}
.cityWiseFuelPriceMainDivContainerTabel {
  width: 100%;
  height: 100%;
}
.cityWiseFuelPriceFirstTabelOption {
  border-top: 1.5px solid rgba(9, 8, 26, 0.08);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cityWiseFuelPriceFirstTabelOption > div {
  width: 25%;
  text-align: end;
  color: black;
  font-family: "Circular-Std-Book";
  font-size: 16px;
  margin-right: 0.5rem;
}

.cityWiseFuelPriceFirstTabelOption > .cityName {
  color: gray;
  font-family: "Circular-Std-Medium";
  text-align: start;
}
.fuelPriceBottomMessage {
  margin-top: 1rem;
  width: 100%;
  height: 70%;
  color: black;
  padding-left: 0.5rem;
  font-size: 14px;
}

/*  fuel price including the city of the state  */
.fuelPriceOptionMainContainer {
  width: 100%;
  height: 40%;
}

.fuelPriceOptionContainer {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: center;
  align-items: center;
}
.petrolFuelPriceMainContainer {
  border: 1px solid rgb(136, 136, 136);
  width: 50%;
  height: 80%;
  border-radius: 10px;
}
.petrolFuelPriceMainContainer {
  display: flex;
  flex-direction: column;
  padding: 4%;
  font-family: "Circular-Std-Book";
  color: black;
}
.petrolFuelImgContainer {
  width: 25%;
  height: 40%;
  margin-bottom: 0.5rem;
}
.petrolFuelImgContainer > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.petrolPrice {
  font-family: "Circular-Std-medium";
  font-size: 21px;
}

.fuelPriceApiFailMainContainer {
  width: 40%;
  height: 150px;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.fuelPriceApiFailImageMainContainer {
  width: 20%;
  margin: auto;
  height: 40%;
}
.fuelPriceApiFailTextMainContainer {
  margin-top: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10%;
  height: 30%;
}
.fuelPriceFailHeading {
  font-family: "Circular-Std-medium";
  font-size: 18px;
  color: black;
}
.fuelPriceFailSubHeading {
  font-family: "Circular-Std-Book";
  font-size: 14px;
  color: gray;
}
.fuelPriceRetry {
  width: 80%;
  margin: auto;
  border: 1.5px solid gray;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 30%;
  color: black;
  font-size: 18px;
  font-family: "Circular-Std-Book";
}

@media (max-width: 767px) {
  .fuelPriceMainContainer {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
  }

  .fuelPriceBackButton {
    height: 100%;
    width: 8%;
    margin-bottom: 1rem;
    background-color: #ffffff;
    background: linear-gradient(
      rgb(239, 233, 251) 0%,
      rgba(239, 233, 251, 0) 100%
    );
    color: #f93356;
  }
  .fuelPriceApiFailMainContainer {
    width: 100%;
  }

  .cityWiseFuelPriceFirstTabelOption > .cityName {
    margin-left: 0.5rem;
  }
  .dieselPrice {
    margin-right: 0.5rem;
  }
}
