/* Define the font faces for different font families */

/* Font face for "Circular-Std-Bold" */
@font-face {
  font-family: "Circular-Std-Bold";
  src: url("./Assets/Fonts/CircularStd/CircularStd-Bold.otf") format("opentype");
  font-display: swap;
}

/* Font face for "Circular-Std-Book" */
@font-face {
  font-family: "Circular-Std-Book";
  src: url("./Assets/Fonts/CircularStd/CircularStd-Book.otf") format("opentype");
  font-display: swap;
}

/* Font face for "Circular-Std-Medium" */ 
@font-face {
  font-family: "Circular-Std-Medium";
  src: url("./Assets/Fonts/CircularStd/CircularStd-Medium.otf")
    format("opentype");
  font-display: swap;
}

/* General styles for the body */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles for code elements */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Styles for button focus (removing the outline) */

button:focus {
  outline: 0;
}

/* Styles for the modal close button */

.modalCloseButton {
  outline: 0 !important;
}
