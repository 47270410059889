.carServiceModalHeaderMainContainer {
  display: flex;
  flex-direction: row;
  gap: 5%;
  align-items: center;
}
.carServiceModalBackButton {
  /* color: #fafafa; */
  font-size: 20px;
  /* background-color: black; */
}

.carServiceModalMainContainer {
  /* border: 2px solid red; */
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carServiceModalInputMainContainer {
  border: 0.5px solid black;
  width: 100%;
  display: flex;
  flex-direction: row;
}
/* .carServiceModalSearchContainer {
    width: 15%;
    height: 100%;
  } */
.carServiceModalInputContainer {
  height: 50%;
  width: 100%;
  border: none;
  font-family: "Circular-Std-Book";
  color: #25252e;
}

.carServiceModalOptionsMainContainer {
  /* border: 2px solid teal; */
  width: 95%;
  height: 80%;
  margin: auto;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
}

.carServiceModalfirstOptionMainContainer {
  /* border: 2px solid green; */
  height: 40%;
  width: 30%;
  font-family: "Circular-Std-Book";
  color: #25252e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.carServiceModalfirstOptionMainContainer > img {
  height: 70%;
  width: 100%;
  object-fit: contain;
}

.carServiceModalfirstOptionText {
  font-size: 12px;
  font-weight: 500;
  font-family: "Circular-Std-Book";
  color: black;
}
