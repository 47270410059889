.pressureVideoMainContainer {
  width: 100%;
  margin: auto;
  margin-top: -9.25%;
  position: relative;
}
.ytp-chrome-bottom {
  display: none !important;
}
.imageVideoMainContainer > img,
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageVideoMainContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.play-button {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.imageVideoMainContainerWindmill {
  width: 100%;
  margin: auto;
  margin-top: -9.25%;
  /* border: 2px solid red; */
  height: 30vh;
}
.imageVideoMainContainerWindmill img {
  object-fit: contain;
}
