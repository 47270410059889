:root {
  --font-size-PPUHead: clamp(1.5em, 2.569vw, 2.313em);
  --font-size-PPSmall: clamp(1rem, 1.181vw, 1.063rem);
  --font-location-ban: 14px;
}
.Popular_place_main_div_ban {
  width: 95%;
  height: 100%;
  margin: auto;
  margin-bottom: 6.2%;
  padding-top: 5%;
}

.Popular_place_heading_ban {
  font-weight: 700;
  font-family: "Circular-Std-Bold";
  color: #09081a;
  font-size: var(--font-size-PPUHead);
  text-align: center;
}

.Popular_place_subheading_ban {
  font-weight: 450;
  color: rgba(9, 8, 26, 0.3);
  font-size: var(--font-size-PPUSmall);
  font-family: "Circular-Std-Book";
  text-align: center;
  padding-top: 5%;
}

.Popular_place_third_div_ban {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
  width: 100%;
  grid-gap: 10% 5%;
  margin-top: 5%;
  padding: 3%;
}
.popular_first_div_ban {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3%;
}
.popular_first_div_img_ban {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popular_first_div_img_ban > img {
  max-width: 70%;
}

.popular_first_div_text_ban {
  font-weight: 700;
  text-align: center;
  color: #09081a;
  font-size: var(--font-location-ban);
  font-family: "Circular-Std-Book";
}
@media (max-width: 768px) {
  .Popular_place_heading_ban {
    font-weight: 700;
    font-family: "Circular-Std-Bold";
    color: #09081a;
    font-size: 24px;
    text-align: center;
  }
  .Popular_place_main_div {
    padding-top: 10%;
  }
}
@media (max-width: 640px) {
  .Popular_place_third_div_ban {
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    width: 100%;
    grid-gap: 5% 5%;
    margin-top: 10%;
    padding: 3%;
  }
}
